import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Alert,
  Input,
  Col,
  Row,
  Card,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  CardBody,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import { BxButton } from "../../Button";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment, { unitOfTime } from "moment-timezone";
import { withTranslation, useTranslation } from "react-i18next";
import "./storageRent.scss";
import { Customer } from "src/models/customer";
import { Storage } from "src/models/storage";
import { Tr, Th, Td } from "react-super-responsive-table";
import { MultiLangText } from "src/models/multiLangText";
import StoragePaidItem from "src/pages/Orders/components/StoragePaidItem";
import ChargeTypePaidItem from "src/pages/Orders/components/ChargeTypePaidItem";
import { Branch } from "src/models/branch";
import { PaidItem } from "src/models/paidItem";
import { ChargeType } from "src/models/chargeType";
import { Contract } from "src/models/contract";
import { InvoiceType } from "src/models/invoice";
import Decimal from "decimal.js";
import { OrderType } from "src/util/orderType";
import { CreateOrderRequest } from "src/helpers/boxapp_order_helper";
import MiscellaneousPaidItem from "src/pages/Orders/components/MiscellaneousPaidItem";
import { ChargeMode } from "src/util/chargeMode";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { RentMode } from "./StorageRent";
import contract from "src/template/contract";
import CurrencySymbol from "src/components/CurrencySymbol";
import { Order } from "src/models/order";

export type StorageRentOrderPanelProps = {
  lastOrder?: Order;
  branch: Branch;
  rentMode: RentMode;
  paidItems: PaidItem[];
  customer?: Customer;
  step: number;
  isFinished: boolean;
  onCancel: () => void;
  open: boolean;
  onSubmit: (order: CreateOrderRequest) => void;
  orderDate: number;
  totalSteps: number;
  contract?: Contract;
};
const StorageRentOrderPanel = (props: StorageRentOrderPanelProps) => {
  const { i18n } = useTranslation();
  const [remarks, setRemarks] = useState<string>();
  const [invoiceRemarks, setInvoiceRemarks] = useState<string>();
  const [itemDropDown, setItemDropDown] = useState<boolean>(false);
  const [storages, setStorages] = useState<Storage[]>();
  const [selectedChargeTypePaidItem, setSelectedChargeTypePaidItem] =
    useState<PaidItem>();
  const [selectedStoragePaidItem, setSelectedStoragePaidItem] =
    useState<PaidItem>();
  const [selectedStorage, setSelectedStorage] = useState<Storage>();
  const [selectedMiscellaneousPaidItem, setSelectedMiscellaneousPaidItem] =
    useState<PaidItem>();
  const [modalStoragePaidItem, setModalStoragePaidItem] =
    useState<boolean>(false);
  const [modalMiscellaneousPaidItem, setModalMiscellaneousPaidItem] =
    useState<boolean>(false);
  const [modalChargeTypePaidItem, setModalChargeTypePaidItem] =
    useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState<Decimal>();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [paidItemTotalOriginalPrice, setPaidItemTotalOriginalPrice] =
    useState<Decimal>();
  const [errorMessage, setErrorMessage] = useState<Map<string, string>>(
    new Map()
  );
  const [finalPrice, setFinalPrice] = useState<Decimal>();
  const [isOpen, setIsOpen] = useState(false);

  const [isAddingMonthChecked, setIsAddingMonthChecked] = useState(false);
  const [addFreeMonthsCount, setAddFreeMonthsCount] = useState<number>(0);
  const [freeMonths, setFreeMonths] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);

  const [freeDays, setFreeDays] = useState<number>(0);
  useEffect(() => {
    if (props.paidItems) {
      setPaidItems(props.paidItems);
    }
  }, [props.paidItems]);

  {
    /* Start - Discount functionality */
  }

  const [discountType, setDiscountType] = useState<
    "percentage" | "freeMonths" | "addFreeMonths" | "freeDays" | null
  >(null);

  const handleCheckboxChange = (
    type: "percentage" | "freeMonths" | "addFreeMonths" | "freeDays"
  ) => {
    setRemarks((prev) => (prev ? prev.replace(/\[.*?\]/g, "") : ""));
    setInvoiceRemarks((prev) => (prev ? prev.replace(/\[.*?\]/g, "") : ""));
    if (discountType === type) {
      if (type === "addFreeMonths" && isAddingMonthChecked) {
        toggleFreeMonths(addFreeMonthsCount);
        setIsAddingMonthChecked(false);
      }
      setDiscountType(null);
      resetInputs();
    } else {
      setDiscountType(type);
    }
  };

  const resetInputs = () => {
    setDiscountPercentage(0);
    setFreeMonths(0);
    setAddFreeMonthsCount(0);
    setIsAddingMonthChecked(false);
    setDiscountAmount(new Decimal(0));
  };

  const applyDiscount = (percentage: number) => {
    const storageItem = paidItems.find((item) => item.item_type === "STORAGE");
    if (storageItem && storageItem.original_price !== undefined) {
      const discount = Math.round(
        new Decimal(storageItem.original_price)
          .times(percentage / 100)
          .toNumber()
      ); // Round to nearest integer
      setDiscountAmount(new Decimal(discount));
      const remarkText = i18n.t("Applied {{percentage}}% Discount", {
        percentage,
      });
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
      setInvoiceRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const applyFreeMonthsAsDiscount = (months: number) => {
    const storageItem = paidItems.find((item) => item.item_type === "STORAGE");
    if (
      storageItem &&
      storageItem.original_price !== undefined &&
      storageItem.rent_period
    ) {
      let discount;
      if (storageItem.rent_type === "MONTH") {
        const monthlyPrice = new Decimal(storageItem.original_price).div(
          storageItem.rent_period
        );
        discount = Math.round(monthlyPrice.times(months).toNumber()); // Round to nearest integer
      } else {
        const startDate = storageItem.start_date
          ? moment.unix(storageItem.start_date)
          : moment();
        const endDate = storageItem.end_date
          ? moment.unix(storageItem.end_date)
          : moment();
        const days = endDate.diff(startDate, "days");

        const monthlyPrice = new Decimal(storageItem.original_price).div(
          days / 30
        );
        discount = Math.round(monthlyPrice.times(months).toNumber()); // Round to nearest integer
      }

      setDiscountAmount(new Decimal(discount));
      const remarkText = i18n.t(
        "Applied {{months}} Month(s) Free as Discount",
        {
          months,
        }
      );
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
      setInvoiceRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const applyFreeDaysAsDiscount = (days: number) => {
    const storageItem = paidItems.find((item) => item.item_type === "STORAGE");

    if (
      storageItem &&
      storageItem.original_price !== undefined &&
      storageItem.start_date !== undefined &&
      storageItem.end_date !== undefined
    ) {
      const startDate = moment.unix(storageItem.start_date);
      const endDate = moment.unix(storageItem.end_date);
      const totalDays = endDate.diff(startDate, "days") + 1;

      const dailyPrice = new Decimal(storageItem.original_price).div(totalDays);
      const discount = Math.round(dailyPrice.times(days).toNumber());
      setDiscountAmount(new Decimal(discount));

      const remarkText = i18n.t("Applied {{days}} Day(s) Free as Discount", {
        days,
      });
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
      setInvoiceRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const toggleFreeMonths = (months: number) => {
    const storageItemIndex = paidItems.findIndex(
      (item) => item.item_type === "STORAGE"
    );
    if (storageItemIndex !== -1) {
      const updatedPaidItems = [...paidItems];
      const storageItem = updatedPaidItems[storageItemIndex];
      let newRentPeriod = storageItem.rent_period;

      if (storageItem.rent_type === "MONTH") {
        newRentPeriod = isAddingMonthChecked
          ? (storageItem.rent_period || 0) - months
          : (storageItem.rent_period || 0) + months;
      }

      const currentEndDate = storageItem.end_date
        ? moment.unix(storageItem.end_date)
        : moment();

      updatedPaidItems[storageItemIndex] = {
        ...storageItem,
        rent_period: newRentPeriod,
        end_date: currentEndDate
          .add(isAddingMonthChecked ? -months : months, "month")
          .unix(),
      };

      setPaidItems(updatedPaidItems);
      setIsAddingMonthChecked(!isAddingMonthChecked);

      if (!isAddingMonthChecked) {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) =>
          prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
        );
        setInvoiceRemarks((prev) =>
          prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
        );
      } else {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) => (prev ? prev.replace(`[${remarkText}]`, "") : ""));
        setInvoiceRemarks((prev) =>
          prev ? prev.replace(`[${remarkText}]`, "") : ""
        );
      }
    }
  };
  {
    /* End - Discount functionality */
  }

  useEffect(() => {
    if (props.paidItems) {
      setPaidItems(props.paidItems);
    }
  }, [props.paidItems]);

  useEffect(() => {
    if (paidItems?.length === 0 && props.open) {
      if (storages?.length && storages?.length > 0) {
        setSelectedStorage(storages[0]);
        setSelectedStoragePaidItem({
          item_type: "STORAGE",
          item_id: storages[0]?.id,
          item_name: {
            en: storages[0]?.code,
            zh: storages[0]?.code,
            cn: storages[0]?.code,
          },
          original_price: 0,
          rent_period: 1,
          rent_type: "YEAR",
          start_date: props.contract?.start_date,
          end_date: props.contract?.start_date
            ? moment
                .unix(props.contract?.start_date)
                .add(1, "year")
                .add(-1, "day")
                .unix()
            : moment().add(1, "year").add(-1, "day").unix(),
        });
        setModalStoragePaidItem(true);
      }
    }
    setPaidItemTotalOriginalPrice(
      paidItems
        ?.filter(
          (paidItem) =>
            paidItem?.item_type !== "WALLET" &&
            paidItem?.item_type !== "PREPAID"
        )
        ?.reduce((a, v) => (a = a.plus(v?.original_price ?? 0)), new Decimal(0))
    );
  }, [paidItems]);

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const discountAmountValue = discountAmount ? discountAmount.toNumber() : 0;

  /*計算有無property 既開始日期超過合約結束日期*/
  const storagePaidItems = paidItems.filter(
    (item) => item.item_type === "STORAGE"
  );
  const contractEndDate = moment
    .unix(props.contract?.end_date as number)
    .startOf("day");
  const showAlert = storagePaidItems.some((item) => {
    const storageStartDate = moment
      .unix(item.start_date as number)
      .startOf("day");
    return storageStartDate.isAfter(contractEndDate);
  });

  const closeModal = () => {
    setModalStoragePaidItem(false);
    setModalChargeTypePaidItem(false);
    setModalMiscellaneousPaidItem(false);
    // setModalWalletPaidItem(false);
  };
  useEffect(() => {
    if (props.contract) {
      const storage_ids = props.contract?.contract_storages?.map(
        (contractStorage) => contractStorage.storage_id
      );

      if (storage_ids && storage_ids.length > 0) {
        getBoxappStorageBackend()
          .getStorages(props.contract?.branch_id, {
            storage_ids: storage_ids,
          })
          .then(({ storages }) => {
            setStorages(storages?.data);
          });
      }
    }
  }, [props.contract]);

  useEffect(() => {
    const newFinalPrice = paidItemTotalOriginalPrice?.minus(
      discountAmount ?? 0
    );
    if (newFinalPrice?.isNegative()) {
      errorMessage.set("finalPrice", i18n.t("price cannot be less than 0"));
      setErrorMessage(errorMessage);
    } else {
      setFinalPrice(newFinalPrice);
    }
  }, [paidItemTotalOriginalPrice, discountAmount]);

  const title = () => {
    if (props.isFinished) {
      const storage = paidItems?.find((x) => x?.item_type === "STORAGE");
      if (storage) {
        return `(${moment
          .unix(Number(storage?.start_date))
          ?.format("YYYY-MM-DD")} - ${moment
          .unix(Number(storage?.end_date))
          ?.format("YYYY-MM-DD")})`;
      } else {
        // Find some other item with start date and end date
        const item = paidItems?.find((x) => x?.start_date && x?.end_date);
        if (item) {
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")} - ${moment
            .unix(Number(item?.end_date))
            ?.format("YYYY-MM-DD")})`;
        } else {
          const item = paidItems?.find((x) => x?.start_date);
          return `(${moment
            .unix(Number(item?.start_date))
            ?.format("YYYY-MM-DD")}  `;
        }
      }
    }
    return "";
  };

  const paidItemDetail = (item: PaidItem) => {
    switch (item?.item_type) {
      case "STORAGE":
        return `${item.rent_period ?? ""} ${
          i18n.t(item.rent_type ?? "") ?? ""
        }`;
      default:
        if (item.quantize) {
          if (item?.charge_mode === ChargeMode.ACCUMLATIVE) {
            return `${new Decimal(item.quantity_end ?? 0)
              .minus(item.quantity_start ?? 0)
              .toNumber()}`;
          }
          if (item?.charge_mode === ChargeMode.STAND_ALONE) {
            return `${Number(item.quantity_start)} ${item.unit}`;
          }
        }

        return "";
    }
  };

  const onSubmit = () => {
    setErrorMessage(new Map());
    const _storage = paidItems?.find((x) => x?.item_type === "STORAGE");
    if (!_storage) {
      errorMessage.set("paid_items", i18n.t("storage cannot be empty"));

      setErrorMessage(errorMessage);
      return;
    }
    let hasError = false;
    const items: PaidItem[] = paidItems?.map((item) => {
      if (!item?.item_type) {
        if (item?.id) {
          errorMessage.set(item?.id, i18n.t("item type cannot be empty"));
          hasError = true;
        }
      }
      item.discount_amount = 0;
      item.final_price =
        Number(item?.original_price) - Number(item?.discount_amount);

      switch (item?.item_type) {
        case "STORAGE":
          item.discount_amount = Number(discountAmount);
          item.item = {
            // ...props.storage,
            branch: undefined,
            categories: undefined,
            charge_types: undefined,
            contract_id: undefined,
            contract: undefined,
            customer_id: undefined,
            features: undefined,
            order: undefined,
            order_id: undefined,
            rent_end_date: undefined,
            rent_records: undefined,
          } as Storage;
          break;
        default:
          if (item?.charge_mode === ChargeMode.ACCUMLATIVE) {
            item.quantity_start = Number(item?.quantity_start);
            item.quantity_end = Number(item?.quantity_end);
          } else if (item?.charge_mode === ChargeMode.STAND_ALONE) {
            item.quantity_start = Number(
              item?.quantity_start ?? item?.default_quantity
            );
          }
          const chargeType = props.branch?.charge_types?.find(
            (chargeType) => chargeType.id === item?.item_id
          );
          item.item = {
            ...item,
            ...chargeType,
            order_id: undefined,
            order_type: undefined,
            invoice_type: undefined,
            invoice_id: undefined,
            item: undefined,
            paid_amount: undefined,
            need_to_pay: undefined,
            rent_type: undefined,
            rent_period: undefined,
            roundUp: undefined,
            roundDown: undefined,
          } as ChargeType;
      }

      return item as PaidItem;
    });
    if (items?.length === 0) {
      errorMessage.set("paid_items", i18n.t("paid items cannot be empty"));
      hasError = true;

      setErrorMessage(errorMessage);
      return;
    }
    if (hasError) {
      errorMessage.set("paid_items", i18n.t("some item has error"));

      setErrorMessage(errorMessage);
      return;
    }

    const finalPrice = paidItemTotalOriginalPrice?.minus(discountAmount ?? 0);
    if (finalPrice?.isNegative()) {
      errorMessage.set("finalPrice", i18n.t("price cannot be less than 0"));

      setErrorMessage(errorMessage);
      return;
    }

    setErrorMessage(new Map());
    props.onSubmit({
      start_date: _storage?.start_date,
      end_date: _storage?.end_date,
      rent_period: _storage?.rent_period,
      customer_id: props.customer?.id,
      original_price: paidItemTotalOriginalPrice?.toNumber(),
      discount_amount: discountAmount?.toNumber(),
      final_price: finalPrice?.toNumber(),
      remarks: remarks ?? "",
      invoice_remarks: invoiceRemarks ?? "",
      branch_id: props.branch?.id,
      paid_items: items,
      contract_id: props.contract?.id,
      rent_type: _storage.rent_type,
    } as CreateOrderRequest);
  };

  return (
    <div>
      <ChargeTypePaidItem
        // order={props.order}
        branch={props.branch}
        invoiceType={InvoiceType.Payment}
        orderType={OrderType?.Rent}
        modal={modalChargeTypePaidItem}
        item={selectedChargeTypePaidItem}
        onSuccess={async (paidItem) => {
          if (selectedChargeTypePaidItem) {
            setPaidItems(
              paidItems?.map((item, i) =>
                item.id === paidItem.id
                  ? {
                      ...paidItem,
                      sort: i,
                    }
                  : item
              )
            );
          } else {
            setPaidItems([
              ...paidItems,
              {
                ...paidItem,
                sort: paidItems?.length,
                id: crypto.randomUUID(),
              },
            ]);
          }
          setSelectedChargeTypePaidItem(undefined);
          closeModal();
        }}
        onClose={closeModal}
        onFailed={() => {
          closeModal();
          // props.onSuccess();
        }}
      />

      {selectedStorage && (
        <StoragePaidItem
          // order={props.order}
          branch={props.branch}
          storage={selectedStorage}
          invoiceType={InvoiceType.Payment}
          orderType={OrderType?.Rent}
          modal={modalStoragePaidItem}
          item={
            selectedStoragePaidItem ?? props.rentMode === RentMode.NewContract
              ? {
                  item_type: "STORAGE",
                  item_id: selectedStorage.id,
                  item_name: {
                    en: selectedStorage.code,
                    zh: selectedStorage.code,
                    cn: selectedStorage.code,
                  },

                  original_price: selectedStoragePaidItem?.original_price,
                  rent_period: selectedStoragePaidItem?.rent_period ?? 1,
                  rent_type: selectedStoragePaidItem?.rent_type ?? "YEAR",
                  start_date: selectedStoragePaidItem?.start_date,
                  end_date: selectedStoragePaidItem?.end_date,
                  charge_mode: ChargeMode.ACCUMLATIVE,

                  payment_account_id:
                    selectedStoragePaidItem?.payment_account_id,
                }
              : undefined
          }
          onSuccess={async (paidItem) => {
            if (selectedStoragePaidItem) {
              //check if the item is already in the list,
              //edit item > paidItem.id is not undefined
              //add item > paidItem.id is undefined, check item.item_id
              const existItem = paidItem.id
                ? paidItems?.find((item) => item.id === paidItem.id)
                : paidItems?.find((item) => item.item_id === paidItem.item_id);
              if (existItem) {
                setPaidItems(
                  paidItems?.map((item, i) => {
                    return !!paidItem.id && item.id === paidItem.id
                      ? {
                          ...paidItem,
                          sort: i,
                        }
                      : item.item_id === paidItem.item_id
                      ? {
                          ...paidItem,
                          sort: i,
                        }
                      : item;
                  })
                );
              } else {
                setPaidItems([
                  ...paidItems,
                  {
                    ...paidItem,
                    sort: paidItems?.length,
                    id: crypto.randomUUID(),
                  },
                ]);
              }
            } else {
              setPaidItems([
                ...paidItems,
                {
                  ...paidItem,
                  sort: paidItems?.length,
                  id: crypto.randomUUID(),
                },
              ]);
            }
            setSelectedStoragePaidItem(undefined);
            closeModal();
          }}
          onClose={closeModal}
          onFailed={() => {
            closeModal();
          }}
        />
      )}
      <MiscellaneousPaidItem
        // order={props.order}
        branch={props.branch}
        invoiceType={InvoiceType.Payment}
        orderType={OrderType?.Rent}
        modal={modalMiscellaneousPaidItem}
        item={selectedMiscellaneousPaidItem}
        onSuccess={async (paidItem) => {
          if (selectedMiscellaneousPaidItem) {
            setPaidItems(
              paidItems?.map((item, i) =>
                item.id === paidItem.id
                  ? {
                      ...paidItem,
                      sort: i,
                    }
                  : item
              )
            );
          } else {
            setPaidItems([
              ...paidItems,
              {
                ...paidItem,
                sort: paidItems?.length,
                id: crypto.randomUUID(),
              },
            ]);
          }
          setSelectedMiscellaneousPaidItem(undefined);
          closeModal();
        }}
        onClose={closeModal}
        onFailed={() => {
          closeModal();
          // props.onSuccess();
        }}
      />
      <AvForm
        hidden={!props.open}
        action="#"
        id={"CollapseForm" + props.step}
        title={`${i18n.t("Order")} ${title()}`}
        onCancel={props.onCancel}
        open={props.open}
      >
        <Card outline color="light" className="border">
          <CardBody>
            <Row>
              <Col xs="12">
                {`${i18n.t("Order Created Date")}: ${moment
                  .unix(props.orderDate)
                  .format("YYYY-MM-DD HH:mm:ss")}`}
              </Col>
            </Row>

            <Row className="mt-3 mb-2">
              <Col xs="10">
                <Label className="font-size-13 font-weight-bold" htmlFor="">
                  {i18n.t("Paid Items")}
                  <a data-tip data-for="paidItemsTips1">
                    {` ${i18n.t("ⓘ")}`}
                  </a>
                </Label>
              </Col>
              <ReactTooltip id="paidItemsTips1" effect="solid">
                <span>
                  <p>
                    {`1. ${i18n.t(
                      "If renewing, the data from the previous rental agreement will be automatically entered. Please remember to update each piece of information."
                    )}`}
                  </p>
                  <p>
                    {`2. ${i18n.t(
                      "The sorting is based on the current sequence of order placement. Rest assured, you can still change your sorting in the order details after placing an order."
                    )}`}
                  </p>
                </span>
              </ReactTooltip>
              <Col xs={12}>{errorMessage.get("paid_items")?.toString()}</Col>
            </Row>

            {showAlert && (
              <Alert
                color="warning"
                className="offcanvas alert-dismissible fade show text-center font-size-13"
                role="alert"
              >
                <p>
                  <b>
                    <i className="bx bx-error bx-tada mr-2 text-danger font-size-20" />
                    {`${i18n.t(
                      "The Property Start Date has exceeded the contract end date. You can use the contract renewal feature, or you may continue to generate rental orders."
                    )}`}
                  </b>
                </p>
              </Alert>
            )}
            <Row className="mb-4">
              <Col xs={12}>
                <table className="table table-striped ">
                  <thead>
                    <Tr>
                      <Th className="co-name text-center">
                        {i18n.t("Paid Items")}
                      </Th>
                      <Th className="co-name">{i18n.t("Items Price")}</Th>
                      <Th className="co-name">{i18n.t("Detail")}</Th>
                      <Th className="co-name">{i18n.t("Start Date")}</Th>
                      <Th className="co-name">{i18n.t("End Date")}</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {paidItems?.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          xl="2"
                          lg="2"
                          sm="4"
                          xs="6"
                          className={`col-sm-1 col-md-1 mt-1 mr-1`}
                        >
                          <Td>
                            <i
                              className="btn text-primary mdi mdi-file-document-edit-outline font-size-18 "
                              onClick={() => {
                                switch (item?.item_type) {
                                  case "STORAGE":
                                    setSelectedStoragePaidItem(item);
                                    setSelectedStorage(
                                      storages?.find(
                                        (storage) =>
                                          storage?.id === item?.item_id
                                      )
                                    );
                                    setModalStoragePaidItem(true);

                                    break;
                                  case "MISCELLANEOUS":
                                    setSelectedMiscellaneousPaidItem(item);
                                    setModalMiscellaneousPaidItem(true);
                                    break;
                                  default:
                                    setSelectedChargeTypePaidItem(item);
                                    setModalChargeTypePaidItem(true);
                                    break;
                                }
                              }}
                            ></i>
                            <i
                              className="btn text-primary mdi mdi-trash-can-outline font-size-18  "
                              onClick={async () => {
                                setPaidItems(
                                  paidItems?.filter(
                                    (paidItem) => paidItem?.id !== item?.id
                                  )
                                );
                              }}
                            ></i>

                            {item?.item_type === "STORAGE"
                              ? `${i18n.t(item?.item_type)} - ${
                                  item?.item_name?.[
                                    i18n.language as keyof MultiLangText
                                  ] ?? ""
                                }`
                              : item?.item_type === "WALLET"
                              ? `${i18n.t("Wallet Paid")}`
                              : item?.item_type === "PREPAID"
                              ? `${i18n.t("Extra Amount")}`
                              : item?.item_type === "REPAY"
                              ? `${i18n.t("Repay")}`
                              : item?.item_name?.[
                                  i18n.language as keyof MultiLangText
                                ] ?? ""}
                            {(item?.id &&
                              errorMessage.get(item?.id)?.toString()) ??
                              ""}
                          </Td>

                          <Td>{item.final_price}</Td>
                          <Td>{paidItemDetail(item)}</Td>
                          <Td>
                            {item.start_date
                              ? moment
                                  .unix(item.start_date as number)
                                  ?.format("YYYY-MM-DD")
                              : "N/A"}
                          </Td>
                          <Td>
                            {item.end_date
                              ? moment
                                  .unix(item.end_date as number)
                                  ?.format("YYYY-MM-DD")
                              : "N/A"}
                          </Td>
                        </Tr>
                      );
                    })}

                    <Tr>
                      <Td>
                        <ButtonDropdown
                          className="text-primary"
                          isOpen={itemDropDown}
                          toggle={() => setItemDropDown(!itemDropDown)}
                        >
                          <DropdownToggle caret color="link">
                            <i className="mdi mdi-plus font-size-18  "></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setSelectedChargeTypePaidItem(undefined);
                                setModalChargeTypePaidItem(true);
                              }}
                            >
                              {i18n.t("Other Charge Items")}
                            </DropdownItem>

                            {storages &&
                              storages?.map((storage, i) => {
                                let address =
                                  storage?.address?.[
                                    i18n.language as keyof MultiLangText
                                  ] ?? "";
                                if (address?.length > 30) {
                                  address = address?.substring(0, 30) + "...";
                                }
                                return (
                                  <DropdownItem
                                    key={i}
                                    onClick={() => {
                                      setSelectedStorage(storage);
                                      setSelectedStoragePaidItem(undefined);
                                      if (storage?.id)
                                        setModalStoragePaidItem(true);
                                    }}
                                  >
                                    {`${i18n.t("Storage")} -  ${
                                      storage?.code
                                    } -${address}`}
                                  </DropdownItem>
                                );
                              })}
                            <DropdownItem
                              onClick={async () => {
                                setSelectedMiscellaneousPaidItem(undefined);
                                setModalMiscellaneousPaidItem(true);
                              }}
                            >
                              {i18n.t("Miscellaneous Item")}
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            <Row>
              <Col xs="5">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )}${i18n.language === "en" ? " " : ""}${i18n.t(
                    "Orignal Price"
                  )}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        <CurrencySymbol
                          currency={props?.branch?.currency ?? "HKD"}
                        />
                      </span>
                    </div>
                    <Input
                      className="bg-light text-gray bg-opacity-10 font-size-13"
                      type="number"
                      maxLength={8}
                      name="finalPrice"
                      value={paidItemTotalOriginalPrice?.toNumber()}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>
                    {i18n.t("Discount Amount")}
                    <a data-tip data-for="tips2">
                      {i18n.t("ⓘ")}
                    </a>
                    <ReactTooltip id="tips2" effect="solid">
                      <span>
                        {i18n.t("Discount count on Rental Location.")}
                      </span>
                    </ReactTooltip>
                  </Label>
                  <AvField
                    name="discountAmount"
                    placeholder={i18n.t("Discount Amount")}
                    type="number"
                    errorMessage={i18n.t("invalid Discount Amount")}
                    className="form-control "
                    id="validatiomDiscountAmount"
                    value={discountAmount ?? 0}
                    onChange={(e: any) => {
                      if (!isNaN(Number(e.target.value))) {
                        setDiscountAmount(new Decimal(e.target.value ?? 0));
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs="3">
                <FormGroup>
                  <Label>{i18n.t(" ")}</Label>
                  <BxButton
                    className="btn btn-secondary waves-effect mt-2 btn-block d-flex justify-content-between align-items-center font-size-13"
                    type="button"
                    color="secondary"
                    onClick={toggleVisibility}
                  >
                    {i18n.t("Apply Discounts")}
                    <i
                      className={`fas fa-chevron-${
                        isOpen ? "up" : "down"
                      } ml-2`}
                    ></i>
                  </BxButton>
                </FormGroup>
              </Col>
            </Row>
            {/*DISCOUNT SECTION*/}

            {isOpen && (
              <Row
                style={{
                  backgroundColor: "#eaeeef",
                  border: "1px solid #dee2e6",
                  borderRadius: "0.25rem",
                  padding: "1rem",
                }}
                className="mb-4 mt-1"
              >
                {/* Discount message */}
                <Col xs="12">
                  <div style={{ color: "red", marginBottom: "10px" }}>
                    {`(${i18n.t("ONLY Apply for the property.")} ${i18n.t(
                      "One discount apply at the same time only"
                    )})`}
                  </div>
                </Col>
                <Col xs="6">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={discountType === "percentage"}
                        onChange={() => handleCheckboxChange("percentage")}
                        disabled={
                          discountType !== null && discountType !== "percentage"
                        }
                        style={{
                          cursor:
                            discountType !== null &&
                            discountType !== "percentage"
                              ? "not-allowed"
                              : "pointer",
                        }}
                      />
                      <span
                        style={{
                          color:
                            discountType !== null &&
                            discountType !== "percentage"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {`${i18n.t("Apply Discount")} %`}
                      </span>
                    </Label>
                    <div className="input-group mt-2">
                      <Input
                        type="number"
                        value={discountPercentage}
                        onChange={(e) =>
                          setDiscountPercentage(Number(e.target.value))
                        }
                        disabled={discountType !== "percentage"}
                        style={{
                          backgroundColor:
                            discountType !== "percentage" ? "#e9ecef" : "white",
                        }}
                      />
                      <Button
                        onClick={() => applyDiscount(discountPercentage)}
                        disabled={discountType !== "percentage"}
                      >
                        {i18n.t("Apply Discount")}
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={discountType === "freeDays"}
                        onChange={() => handleCheckboxChange("freeDays")}
                        disabled={
                          discountType !== null && discountType !== "freeDays"
                        }
                        style={{
                          cursor:
                            discountType !== null && discountType !== "freeDays"
                              ? "not-allowed"
                              : "pointer",
                        }}
                      />
                      <span
                        style={{
                          color:
                            discountType !== null && discountType !== "freeDays"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {i18n.t("Apply X Day Free (as discount)")}
                        <a data-tip data-for="applyXDayFreeTips1">
                          {` ${i18n.t("ⓘ")}`}
                        </a>
                      </span>

                      <ReactTooltip id="applyXDayFreeTips1" effect="solid">
                        <span>
                          <p>
                            {`${i18n.t(
                              "Count the daily rate (Total Day / Rental Price) times X days."
                            )}`}
                          </p>
                        </span>
                      </ReactTooltip>
                    </Label>
                    <div className="input-group mt-2">
                      <Input
                        type="number"
                        value={freeDays}
                        onChange={(e) => setFreeDays(Number(e.target.value))}
                        disabled={discountType !== "freeDays"}
                        style={{
                          backgroundColor:
                            discountType !== "freeDays" ? "#e9ecef" : "white",
                        }}
                      />
                      <Button
                        onClick={() => applyFreeDaysAsDiscount(freeDays)}
                        disabled={discountType !== "freeDays"}
                      >
                        {i18n.t("Apply Free Days")}
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
                {
                  <Col xs="6">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={discountType === "freeMonths"}
                          onChange={() => handleCheckboxChange("freeMonths")}
                          disabled={
                            discountType !== null &&
                            discountType !== "freeMonths"
                          }
                          style={{
                            cursor:
                              discountType !== null &&
                              discountType !== "freeMonths"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        />
                        <span
                          style={{
                            color:
                              discountType !== null &&
                              discountType !== "freeMonths"
                                ? "gray"
                                : "black",
                          }}
                        >
                          {i18n.t("Apply X Month Free (as discount)")}
                          <a data-tip data-for="applyXMonthFreeTips1">
                            {` ${i18n.t("ⓘ")}`}
                          </a>
                        </span>

                        <ReactTooltip id="applyXMonthFreeTips1" effect="solid">
                          <span>
                            <p>
                              {`${i18n.t(
                                "If the Rent Period is not MONTH, that will count the daily rate (Total Day / Rental Price) times 30 days."
                              )}`}
                            </p>
                          </span>
                        </ReactTooltip>
                      </Label>
                      <div className="input-group mt-2">
                        <Input
                          type="number"
                          value={freeMonths}
                          onChange={(e) =>
                            setFreeMonths(Number(e.target.value))
                          }
                          disabled={discountType !== "freeMonths"}
                          style={{
                            backgroundColor:
                              discountType !== "freeMonths"
                                ? "#e9ecef"
                                : "white",
                          }}
                        />
                        <Button
                          onClick={() => applyFreeMonthsAsDiscount(freeMonths)}
                          disabled={discountType !== "freeMonths"}
                        >
                          {i18n.t("Apply Free Months")}
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                }
                <Col xs="6">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={discountType === "addFreeMonths"}
                        onChange={() => handleCheckboxChange("addFreeMonths")}
                        disabled={
                          discountType !== null &&
                          discountType !== "addFreeMonths"
                        }
                        style={{
                          cursor:
                            discountType !== null &&
                            discountType !== "addFreeMonths"
                              ? "not-allowed"
                              : "pointer",
                        }}
                      />
                      <span
                        style={{
                          color:
                            discountType !== null &&
                            discountType !== "addFreeMonths"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {i18n.t("Add X Month Free")}
                      </span>
                    </Label>
                    <div className="input-group mt-2">
                      <Input
                        type="number"
                        value={addFreeMonthsCount}
                        onChange={(e) =>
                          setAddFreeMonthsCount(Number(e.target.value))
                        }
                        disabled={discountType !== "addFreeMonths"}
                        style={{
                          backgroundColor:
                            discountType !== "addFreeMonths"
                              ? "#e9ecef"
                              : "white",
                        }}
                      />
                      <Button
                        color={isAddingMonthChecked ? "primary" : "secondary"}
                        onClick={() => {
                          toggleFreeMonths(addFreeMonthsCount);
                          setIsAddingMonthChecked(!isAddingMonthChecked);
                        }}
                        disabled={discountType !== "addFreeMonths"}
                      >
                        {isAddingMonthChecked
                          ? i18n.t("Remove Free Months")
                          : i18n.t("Add Free Months")}
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="12">
                <FormGroup className="mb-4">
                  <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                    "Order"
                  )}${i18n.language === "en" ? " " : ""}${i18n.t(
                    "Final Price"
                  )}`}</Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text font-size-13 font-weight-bold">
                        <CurrencySymbol
                          currency={props?.branch?.currency ?? "HKD"}
                        />
                      </span>
                    </div>
                    <Input
                      disabled
                      className="bg-light text-gray bg-opacity-10 font-weight-bold font-size-15"
                      type="number"
                      maxLength={8}
                      name="finalPrice"
                      value={finalPrice?.toNumber() ?? 0}
                    />
                  </div>
                  <div className="error">
                    {errorMessage.get("final_price")?.toString() ?? ""}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row className="border-top border-5 pt-4 md-4">
              <Col xs="6" className="d-flex flex-column align-items-start pe-2">
                <Label className="font-12 mb-2">
                  {`${i18n.t("Order")} & ${i18n.t("Invoice")}${
                    i18n.language === "en" ? " " : ""
                  }${i18n.t("Remarks")}`}
                </Label>
                <Input
                  type="textarea"
                  id="orderRemarks"
                  maxLength={500}
                  name="remarks"
                  onChange={(e) => {
                    setRemarks(e.target.value ?? "");
                    setInvoiceRemarks(e.target.value ?? "");
                  }}
                  value={remarks}
                  className="w-100 p-2 bg-white border scrollable-container"
                  style={{ height: "100px", resize: "none", overflow: "auto" }}
                />
              </Col>

              <Col
                xs="2"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    setRemarks(props?.lastOrder?.remarks);
                    setInvoiceRemarks(props?.lastOrder?.remarks);
                  }}
                >
                  <i className="fas fa-arrow-left">{i18n.t("COPY")}</i>
                </Button>
              </Col>
              <Col xs="4" className="d-flex flex-column align-items-start ps-2">
                <Label className="font-12 mb-2">
                  {i18n.t("Last Order Remarks")}
                </Label>
                <div
                  className="w-100 p-2 bg-light border"
                  style={{ height: "100px", overflowY: "auto" }}
                >
                  <div
                    className="font-size-12 scrollable-container"
                    style={{ whiteSpace: "pre-wrap", overflowY: "auto" }}
                  >
                    {props?.lastOrder?.remarks}
                  </div>
                </div>
              </Col>
            </Row>

            <BxButton
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
              x
              onClick={() => {
                onSubmit();
              }}
            >
              {i18n.t("Submit")}
            </BxButton>
            {props.totalSteps === 1 && (
              <BxButton
                type="submit"
                color="secondary"
                className="mt-3 ml-2"
                form={"CollapseForm" + props.step}
                onClick={props.onCancel}
              >
                {i18n.t("Close")}
              </BxButton>
            )}
          </CardBody>
        </Card>
      </AvForm>
    </div>
  );
};

export default withTranslation()(StorageRentOrderPanel);
