import React from "react";

interface CurrencySymbolProps {
  currency?: string;
}

const CurrencySymbol: React.FC<CurrencySymbolProps> = ({ currency }) => {
  // 先確保有有效的貨幣代碼
  const currencyCode = currency?.trim() || "HKD";

  try {
    const symbol =
      new Intl.NumberFormat("zh-HK", {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "narrowSymbol",
      })
        .formatToParts(0)
        .find((part) => part.type === "currency")?.value || "$";

    return <>{symbol}</>;
  } catch (error) {
    console.warn(`Invalid currency code: ${currencyCode}, fallback to $`);
    return <>$</>;
  }
};

export default CurrencySymbol;
