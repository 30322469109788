import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import "./storage.scss";
// Redux
import { MODAL_TYPES } from "../ModalProps";

import { useGlobalModalContext } from "../../../context/GlobalModalContext";

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { useTranslation } from "react-i18next";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import { DepositOrder } from "src/models/depositOrder";
import { Customer } from "src/models/customer";
import { Order } from "src/models/order";
import { Storage } from "src/models/storage";
import SectionInputformWithFocusStyle from "src/pages/Storages/components/SectionInputformWithFocusStyle";
import SectionDividerWithLeftText from "src/pages/Storages/components/SectionDividerWithLeftText";
import moment from "moment";

interface StorageCancelProps extends RouteComponentProps {
  modal: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  storage: Storage;
  depositOrder: DepositOrder;
  onClose: () => void;
  onSuccess: (mode: string, storage: any) => void;
}

const StorageCancel = (props: StorageCancelProps) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { contextBranch } = useBranchContext();

  const togglemodal = () => {
    props.onClose();
  };

  const submitRent = () => {
    if (!props.customer) {
      return;
    }

    showModal(MODAL_TYPES.ACTION_MODAL, {
      title: i18n.t("Are you Confirm?"),
      message: i18n.t(
        `Please confirm the Cancellation for Rental Location - {{storageId}}`,
        { storageId: contextStorage?.code }
      ),
      onConfirm: () => {
        showModal(MODAL_TYPES.REMOVAL_ACTION_MODAL, {
          title: i18n.t("Confirm to Cancel"),
          onConfirm: () => {
            cancelStorage();
          },
        });
      },
    });
  };

  const cancelStorage = async () => {
    if (!contextBranch?.id) {
      return;
    }

    if (!contextStorage?.id) {
      return;
    }
    const response = await getBoxappStorageBackend().cancel(
      contextBranch?.id,
      contextStorage?.id,
      {
        order_id: props.order.id,
      }
    );
    if (response?.storage) props.onSuccess(props.mode, response.storage);
  };

  return !props.order ? null : (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Cancel Rental Record")}
          </ModalHeader>

          <ModalBody>
            <Alert
              color="success"
              className="offcanvas alert-dismissible fade show mt-5 md-5 p-3 text-center font-size-13"
              role="alert"
            >
              <i className="far fa-smile mr-2 mb-2" />
              <p className="mb-2">
                <b>
                  {i18n.t(
                    "This function is for canceling 'this' rental record."
                  )}
                </b>
              </p>
              <p className="mb-2">
                <b>
                  {i18n.t(
                    "If this is the first rental record, the contract will also be cancelled; otherwise, only this record will be cancelled."
                  )}
                </b>
              </p>
              <p className="mb-2">
                <b>
                  {i18n.t("lease select 'Current Rental' after cancellation.")}
                </b>
              </p>
            </Alert>

            <AvForm action="#" onSubmit={submitRent}>
              <Card outline color="dark" className="border">
                <CardBody>
                  <SectionDividerWithLeftText
                    label={`${i18n.t("Rental Information")}`}
                  ></SectionDividerWithLeftText>

                  <Row>
                    <Col xs="6">
                      <Label>{i18n.t("Storage")}</Label>
                      <p>{contextStorage?.code}</p>
                    </Col>
                    <Col xs="6">
                      <p>{props?.customer?.other_name}</p>
                    </Col>
                  </Row>

                  <SectionDividerWithLeftText
                    label={`${i18n.t("Document Information")}`}
                  ></SectionDividerWithLeftText>
                  <Row>
                    <Col xs="6">
                      <Label>{i18n.t("Contract No")}</Label>
                      <p>{props.order.contract_id}</p>
                    </Col>
                    <Col xs="6">
                      <Label>{i18n.t("Order No")}</Label>
                      <p>{props.order.order_no}</p>
                    </Col>
                  </Row>

                  <SectionDividerWithLeftText
                    label={`${i18n.t("Order Period")}`}
                  ></SectionDividerWithLeftText>
                  <Row>
                    <Col xs="6">
                      <Label>{i18n.t("Order Start Date")}</Label>
                      <p>
                        {moment
                          .unix(props?.order?.start_date)
                          .format("YYYY-MM-DD")}
                      </p>
                    </Col>
                    <Col xs="6">
                      <Label>{i18n.t("Order End Date")}</Label>
                      <p>
                        {moment
                          .unix(props?.order?.end_date)
                          .format("YYYY-MM-DD")}
                      </p>
                    </Col>
                  </Row>

                  <BxButton type="submit" color="success">
                    {i18n.t("Submit")}
                  </BxButton>
                  <Button type="button" color="secondary" onClick={togglemodal}>
                    {i18n.t("Close")}
                  </Button>
                </CardBody>
              </Card>
            </AvForm>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(StorageCancel));
