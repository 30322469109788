import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../../context/AuthProviderContext";
import { useTranslation } from "react-i18next";
import {
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
  InputGroup,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import "./paymentInfo.scss";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { BxButton } from "../../../components/Button";
import { optionPayment, optionPaymentStatusGroup } from "../../../util/options";
import { MODAL_TYPES } from "../../../components/Modal/ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import UploadAttachmentDialog from "../../../components/Modal/Attachments/uploadAttachmentDialog";

import { Wallet } from "src/models/wallet";
import { Invoice } from "src/models/invoice";
import { Payment, PaymentStatus } from "src/models/payment";
import { getBoxappInvoiceBackend } from "src/helpers/boxapp_invoice_helper";
import { getBoxappOrderBackend } from "src/helpers/boxapp_order_helper";
import { PaidItem } from "src/models/paidItem";
import { MultiLangText } from "src/models/multiLangText";
import { randomUUID } from "crypto";
import Decimal from "decimal.js";
import { getBoxappPaymentBackend } from "src/helpers/boxapp_payment_helper";

import PNGcon from "../../../assets/images/png.png";
import { paidItemStatusColor } from "src/util/color";
import PaymentAllocation from "./paymentAllocation";
import { useBranchContext } from "src/context/BranchProviderContext";
import { getBoxappBranchBackend } from "src/helpers/boxapp_branch_helper";
import { Tab } from "react-bootstrap";
import { Td, Th, Tr } from "react-super-responsive-table";
import CurrencySymbol from "src/components/CurrencySymbol";

export interface PaymentInfoProps {
  invoice?: Invoice;
  payment?: Payment;
  customerId?: string;
  branchId?: string;
  wallet?: Wallet;
  mode: "edit" | "create" | "view";
  onPaidSuccess?: (payment: Payment) => void;
  onClose?: () => void;
}

const PaymentInfo = (props: PaymentInfoProps) => {
  const { i18n } = useTranslation();
  const [remarks, setRemarks] = useState<string>();
  const [modal, setModal] = useState(false);

  const { contextBranch } = useBranchContext();
  const [optionBankAccount, setOptionBankAccount] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const { showModal, loading, setLoading } = useGlobalModalContext();
  const [paymentMethodGroup, setPaymentMethodGroup] = useState<{
    label: string;
    value: string;
  }>();
  const [bankAccountGroup, setBankAccountGroup] = useState<{
    label: string;
    value: string;
  }>();
  const [paymentStatusGroup, setPaymentStatusGroup] = useState<{
    label: string;
    value: string;
  }>();
  const [refNo, setRefNo] = useState<string>();
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [walletPaymentAmount, setWalletPaymentAmount] = useState(0);
  const [uploadedDocs, setUploadedDocs] = useState<File[]>([]);
  const [paymentStatusError, setpaymentStatusError] = useState<string>();
  const [paymentMethodGroupError, setPaymentMethodGroupError] =
    useState<string>();
  const [bankAccountGroupError, setBankAccountGroupError] = useState<string>();
  const [paymentDateError, setPaymentDateError] = useState<string>();
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false);
  const wallet = props.wallet;
  const [allocationModal, setAllocationModal] = useState(false);
  const [payment, setPayment] = useState<Payment | undefined>(props.payment);
  const [paymentConfirmed, setPaymentConfirmed] = useState(
    payment?.status === "PAID" ||
      payment?.status === "VOID" ||
      payment?.status === "CANCELLED"
  );
  const handlePaymentAmountChange = (value: number) => {
    setAmount(value);
    setPaymentConfirmed(false);
  };

  const [walletError, setWalletError] = useState<string | null>(null);
  const [walletPaymentAmountError, setWalletPaymentAmountError] =
    useState(false);

  useEffect(() => {
    if (paymentMethodGroup?.value) {
      if (contextBranch) {
        const s = contextBranch.branch_banks
          ?.filter((x) => x.payment_method === paymentMethodGroup?.value)
          ?.map((bank) => {
            return {
              label: `${bank.bank_name} - ${bank.account_no}`,
              value: bank.id,
            };
          });
        setOptionBankAccount(s);
      } else if (props.branchId) {
        (async function (branchId: string) {
          const { branch } = await getBoxappBranchBackend().getBranch(branchId);
          if (branch) {
            console.log(branch.branch_banks);
            setOptionBankAccount(
              branch?.branch_banks
                ?.filter((x) => x.payment_method === paymentMethodGroup?.value)
                ?.map((bank) => {
                  return {
                    label: bank.account_no,
                    value: bank.id,
                  };
                })
            );
          }
        })(props.branchId);
      }
    }
  }, [contextBranch, props.branchId, paymentMethodGroup]);

  const handleWalletPaymentAmountChange = (value: number) => {
    setWalletPaymentAmount(value);
    setPaymentConfirmed(false);

    {
      /*Check if entered walletPaymentAmount is greater than available wallet amount*/
    }
    if (value > (wallet?.amount || 0)) {
      setWalletError(
        i18n.t("Entered amount exceeds available wallet balance.")
      );
    } else {
      setWalletError(null);
    }
  };
  const uploadAttachment = async (file: File) => {
    try {
      if (props.invoice?.order_id) {
        await getBoxappOrderBackend().uploadAttachment(
          props.invoice?.order_id,
          {
            file: file,
          }
        );
        setUploadedDocs([...uploadedDocs, file]);
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: i18n.t("Upload Success"),
          messageType: "success",
          message: i18n.t("Upload Success"),
        });
      }
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("Upload failed"),
        messageType: "error",
        message: errorMsg,
      });
    }
  };

  const fetchPayment = useCallback(() => {
    if (props.payment?.id) {
      getBoxappPaymentBackend()
        .getPayment(props.payment?.id)
        .then((res) => {
          setPayment(res?.payment);
        });
    }
  }, [props.payment]);
  useEffect(() => {
    if (!props.payment) {
      fetchPayment();
    }
  }, [props.payment]);

  const [paymentDate, setPaymentDate] = useState(new Date());
  const { checkPermission } = useAuthContext();
  const storageItemRows = (paidItem: PaidItem) => {
    return (
      <Row className="mb-1" key={paidItem?.id ?? randomUUID()}>
        <UploadAttachmentDialog
          modal={modal}
          onSuccess={async (file: File) => {
            await uploadAttachment(file);
            setModal(false);
          }}
          onClose={() => setModal(false)}
        />
        <div className="form-check mb-3 ml-4"></div>
        <Col xs="3">
          {`${i18n.t(paidItem?.item_type ?? "")} - ${
            paidItem?.item_name?.[i18n.language as keyof MultiLangText] ?? ""
          }`}
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"payAmt-" + paidItem.id}
              value={Number(paidItem?.final_price ?? 0)}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"payPaidAmt-" + paidItem.id}
              value={Number(paidItem?.paid_amount ?? 0)}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"pendingAmount-" + paidItem.id}
              value={Number(
                (paidItem?.final_price ?? 0) - (paidItem?.paid_amount ?? 0)
              )}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="">
            {paidItem?.status && (
              <span
                className={`font-size-13 ml-2 badge badge-pill ${paidItemStatusColor(
                  paidItem?.status ?? ""
                )} `}
              >
                {i18n.t(paidItem?.status) ?? ""}
              </span>
            )}
          </div>
        </Col>
      </Row>
    );
  };
  const paidItemItemRows = (paidItem: PaidItem) => {
    return (
      <Row className="mb-1" key={paidItem?.id ?? randomUUID()}>
        <div className="form-check mb-3 ml-4"></div>
        <Col xs="3">
          {`${
            paidItem?.item_name?.[i18n.language as keyof MultiLangText] ?? ""
          }`}
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"payAmt-" + paidItem.id}
              value={Number(paidItem?.final_price ?? 0)}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"payPaidAmt-" + paidItem.id}
              value={Number(paidItem?.paid_amount ?? 0)}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="input-group">
            <Input
              disabled={true}
              className={"bg-light text-gray bg-opacity-10"}
              type="number"
              maxLength={8}
              name={"pendingAmount-" + paidItem.id}
              value={Number(
                (paidItem?.final_price ?? 0) - (paidItem?.paid_amount ?? 0)
              )}
            />
          </div>
        </Col>
        <Col xs="2">
          <div className="">
            {paidItem?.status && (
              <span
                className={`font-size-13 ml-2 badge badge-pill ${paidItemStatusColor(
                  paidItem?.status ?? ""
                )} `}
              >
                {i18n.t(paidItem?.status) ?? ""}
              </span>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const paidItemTable = () => {
    return (
      <div className="mb-4">
        <Row className="border-top border-5 pt-4 md-4">
          <div className="form-check  ml-4"></div>
          <Col xs="3">
            <Label className="font-12" htmlFor="">
              {i18n.t("Paid Items")}
            </Label>
          </Col>
          <Col xs="2">
            <Label className="font-12" htmlFor="">
              {i18n.t("Items Price")}{" "}
              <CurrencySymbol currency={contextBranch?.currency ?? "HKD"} />
            </Label>
          </Col>
          <Col xs="2">
            <Label className="font-12" htmlFor="">
              {i18n.t("Paid Price")}{" "}
              <CurrencySymbol currency={contextBranch?.currency ?? "HKD"} />
            </Label>
          </Col>
          <Col xs="2">
            <Label className="font-12" htmlFor="">
              {i18n.t("Pending Price")}{" "}
              <CurrencySymbol currency={contextBranch?.currency ?? "HKD"} />
            </Label>
          </Col>
          <Col xs="2">
            <Label className="font-12" htmlFor="">
              {i18n.t("Status")}
            </Label>
          </Col>
        </Row>

        {props.invoice?.paid_items?.map((paidItem, i) => {
          switch (paidItem?.item_type) {
            case "STORAGE":
              return storageItemRows(paidItem);
            // case "WALLET":
            //   return storageItemRows(paidItem);
            // case "PREPAID":
            //   return storageItemRows(paidItem);
            default:
              return paidItemItemRows(paidItem);
          }
        })}
      </div>
    );
  };
  const paymentItemTable = () => {
    return (
      <div className="mb-4">
        <Row className="border-top border-5 pt-4 md-4">
          <div className="form-check  ml-4"></div>
          <Col xs="3">
            <Label className="font-12" htmlFor="">
              {i18n.t("Paid Items")}
            </Label>
          </Col>
          <Col xs="2">
            <Label className="font-12" htmlFor="">
              {i18n.t("Paid")} $
            </Label>
          </Col>

          <Col>
            {payment?.status === "UNALLOCATED" && (
              <div className="text-sm-right">
                <BxButton
                  type="button"
                  color="danger"
                  className="ml-4"
                  onClick={() => {
                    setAllocationModal(true);
                  }}
                >
                  {i18n.t("Allocate Payment")}
                </BxButton>
              </div>
            )}
          </Col>
        </Row>

        {payment?.payment_items?.map((payment_item, i) => {
          return (
            <Row key={i}>
              <div className="form-check  ml-4"></div>
              <Col xs="3">
                {`${
                  payment_item?.paid_item?.item_name?.[
                    i18n.language as keyof MultiLangText
                  ] ?? ""
                }`}
              </Col>
              <Col xs="2">{payment_item?.amount}</Col>
            </Row>
          );
        })}
      </div>
    );
  };
  useEffect(() => {
    if (props.mode === "edit" || props.mode === "view") {
      if (!props.payment) {
        return;
      }

      setRemarks(props.payment?.remarks);
      setAmount(Number(props.payment?.amount));
      setPrice(Number(props.payment?.billing_amount));
      setRefNo(props.payment?.ref_no);

      if (props.payment?.payment_date) {
        setPaymentDate(new Date(props.payment?.payment_date * 1000));
      }
      if (props.payment?.status) {
        const op = optionPaymentStatusGroup[0].options.find((option) => {
          return option.value === props.payment?.status;
        });
        setPaymentStatusGroup(op);
      }
      if (props.payment?.payment_method) {
        const op = optionPayment[0].options.find((option) => {
          return option.value === props.payment?.payment_method;
        });
        setPaymentMethodGroup(op);
      }
    } else if (props.mode === "create") {
      setRemarks(undefined);
      setAmount(0);
      setPrice(
        props.invoice?.paid_items?.reduce(
          (a, b) =>
            (a = new Decimal(a ?? 0)
              .plus(new Decimal(b.final_price ?? 0))
              .minus(new Decimal(b.paid_amount ?? 0))
              .toNumber()),
          0
        ) ?? 0
      );
      setRefNo(undefined);
      setPaymentDate(new Date());
      setPaymentMethodGroup(undefined);
      setPaymentStatusGroup(undefined);
    }
  }, [props.mode]);

  const onSubmit = async () => {
    if (!paymentMethodGroup) {
      setPaymentMethodGroupError(
        i18n.t("Payment Method") + " " + i18n.t("is required")
      );

      return;
    } else {
      setPaymentMethodGroupError(undefined);
    }
    if (props.mode === "edit") {
      try {
        if (props.payment?.id) {
          const req = {
            ref_no: refNo ?? "",
            remarks: remarks ?? "",
            payment_method: paymentMethodGroup?.value,
          };
          const { payment } = await getBoxappInvoiceBackend().updatePayment(
            props.payment?.id,
            req
          );
          if (payment) {
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: i18n.t("Update Payment Success"),
              messageType: "success",
              message: i18n.t("Update Payment Success"),
            });

            setPayment(payment);
            if (payment.status === "PAID") {
              if (props.onPaidSuccess) {
                props.onPaidSuccess(payment);
              }
            }
          }
        }
      } catch (e) {
        showModal(MODAL_TYPES.ALERT_MODAL, {
          title: i18n.t((e as any)?.data?.messa),
          messageType: "error",
          message: (e as any)?.data?.detail ?? e,
        });
      } finally {
        setSubmitButtonBlock(false);
      }
      return;
    }

    setPaymentDateError(undefined);
    setSubmitButtonBlock(true);
    setTimeout(() => {
      setSubmitButtonBlock(false);
    }, 3000);

    try {
      if (
        props.invoice?.id &&
        props.invoice?.invoice_type &&
        props.invoice?.order_type
      ) {
        const req = {
          invoice_type: props.invoice?.invoice_type,
          order_type: props.invoice?.order_type,
          ref_no: refNo,
          payment_amount:
            props.invoice?.order_type === "Deposit"
              ? Number(price)
              : Number(amount),
          remarks: remarks,
          payment_method: paymentMethodGroup.value,
          payment_date: moment(paymentDate).unix(),
          wallet_id: Number(walletPaymentAmount) > 0 ? wallet?.id : undefined,
          wallet_payment_amount: Number(walletPaymentAmount),
          bank_id: bankAccountGroup?.value,
        };
        const { payment } = await getBoxappOrderBackend().createPayment(
          props.invoice?.id,
          req
        );
        if (payment) {
          showModal(MODAL_TYPES.ALERT_MODAL, {
            title: i18n.t("Create Payment Success"),
            messageType: "success",
            message: i18n.t("Create Payment Success"),
          });
          setPayment(payment);

          if (payment.status === "PAID") {
            if (props.onPaidSuccess) {
              props.onPaidSuccess(payment);
            }
          } else if (payment.status === "UNALLOCATED") {
            setAllocationModal(true);
          }
        }
      }
    } catch (e) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t((e as any)?.data?.messa),
        messageType: "error",
        message: (e as any)?.data?.detail ?? e,
      });
    } finally {
      setSubmitButtonBlock(false);
    }
  };

  return (
    <div>
      {payment?.status === PaymentStatus.UNALLOCATED && (
        <PaymentAllocation
          payment={payment}
          modal={allocationModal}
          onSuccess={function (payment: Payment): void {
            setAllocationModal(false);
            props.onPaidSuccess && props.onPaidSuccess(payment);
          }}
          onFailed={function (): void {
            setAllocationModal(false);
          }}
          onClose={function (): void {
            setAllocationModal(false);
          }}
        />
      )}
      <AvForm action="#" onValidSubmit={onSubmit}>
        <Card>
          <CardBody>
            <Row className="mb4">
              <Col>
                <Label>{i18n.t("Payment No")}:</Label>
                <span className="text-primary ml-2">
                  {props.payment?.payment_no ?? ""}
                </span>
                <span
                  className={`font-size-13 ml-2 badge badge-pill ${
                    props.invoice?.order_type === "Deposit"
                      ? "badge-primary"
                      : "badge-success"
                  }`}
                >
                  {i18n.t(props.invoice?.order_type ?? "")}
                </span>
                {props.invoice?.invoice_type === "Refund" && (
                  <span
                    className={`font-size-13 ml-2 badge badge-pill badge-danger`}
                  >
                    {i18n.t(props.invoice?.invoice_type ?? "")}
                  </span>
                )}
              </Col>
              <Col>
                <div className="text-sm-right">
                  {i18n.t("created_at")}
                  {props.invoice?.created_at
                    ? moment
                        .unix(props.invoice?.created_at)
                        .format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                  {props.payment && props.payment?.status === "PAID" && (
                    <BxButton
                      type="button"
                      color="danger"
                      className="ml-4"
                      onClick={() => {
                        showModal(MODAL_TYPES.ACTION_MODAL, {
                          title: i18n.t("Are you Confirm?"),
                          message: i18n.t(
                            `Please confirm to void the payment. If the payment was prepaid to the Wallet, the funds will remain in the Wallet. You will need to withdraw from the customer details if necessary.`
                          ),
                          onConfirm: async () => {
                            if (props.payment?.id) {
                              try {
                                const { payment } =
                                  await getBoxappInvoiceBackend().voidPayment(
                                    props.payment?.id
                                  );
                                if (payment) {
                                  showModal(MODAL_TYPES.ALERT_MODAL, {
                                    title: i18n.t("Void Payment Success"),
                                    messageType: "success",
                                    message: i18n.t("Void Payment Success"),
                                  });
                                  if (props.onPaidSuccess) {
                                    props.onPaidSuccess(payment);
                                  }
                                }
                              } catch (e) {
                                showModal(MODAL_TYPES.ALERT_MODAL, {
                                  title: i18n.t((e as any)?.data?.messa),
                                  messageType: "error",
                                  message: (e as any)?.data?.detail ?? e,
                                });
                              }
                            }
                          },
                          onCancel: () => {},
                        });
                      }}
                    >
                      {i18n.t("Void Payment")}
                    </BxButton>
                  )}
                </div>
              </Col>
            </Row>
            {props.payment && (
              <FormGroup>
                <Label>{i18n.t("Payment Status")}: </Label>
                <Select
                  required
                  value={paymentStatusGroup}
                  isDisabled={true}
                  onChange={(e: any) => setPaymentStatusGroup(e)}
                  options={optionPaymentStatusGroup}
                  classNamePrefix="select-selection"
                />
                <div className={"error"}>
                  {paymentStatusError ? paymentStatusError : ""}
                </div>
              </FormGroup>
            )}
            <FormGroup>
              <Label>{i18n.t("Paid At")}:</Label>
              <InputGroup>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  className={
                    props.payment
                      ? "bg-light text-gray bg-opacity-10 form-control"
                      : "form-control"
                  }
                  readOnly={props.payment}
                  required
                  selected={paymentDate}
                  onChange={(date: Date) => setPaymentDate(date)}
                />
              </InputGroup>
              <div className={"error"}>
                {paymentDateError ? paymentDateError : ""}
              </div>
            </FormGroup>
            <FormGroup>
              <Label> {i18n.t("Payment Method")}: </Label>
              <Select
                required
                isDisabled={
                  props.payment?.status === "VOID" ||
                  props.payment?.status === "PAID" ||
                  props.payment?.status === "UNALLOCATED" ||
                  props.payment?.status === "CANCELLED"
                }
                placeholder={i18n.t("Select...")}
                value={paymentMethodGroup}
                onChange={(e: any) => setPaymentMethodGroup(e)}
                options={optionPayment}
                classNamePrefix="select-selection"
              />
              <div className={"error"}>
                {paymentMethodGroupError ? paymentMethodGroupError : ""}
              </div>
            </FormGroup>
            {/* <FormGroup>
              <Label> {i18n.t("Deposit Account")}: </Label>
              <Select
                required
                placeholder={i18n.t("Select...")}
                value={bankAccountGroup}
                onChange={(e: any) => setBankAccountGroup(e)}
                options={optionBankAccount}
                classNamePrefix="select-selection"
              />
              <div className={"error"}>
                {bankAccountGroupError ? bankAccountGroupError : ""}
              </div>
            </FormGroup> */}
            {props.mode === "create" ? paidItemTable() : paymentItemTable()}
            {!props.payment && (
              <FormGroup className="border-top border-5 pt-4 md-4">
                <Label>{i18n.t("Billing Amount")}</Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <CurrencySymbol
                        currency={contextBranch?.currency ?? "HKD"}
                      />
                    </span>
                  </div>
                  <Input
                    className={"bg-light text-gray bg-opacity-10"}
                    disabled={true}
                    value={Number(price ?? 0)}
                    name="price"
                    type="number"
                  />
                </div>
              </FormGroup>
            )}
            <FormGroup className="mb-4">
              <Label htmlFor="validationCustomName">
                {i18n.t("Pay Amount")}
                {!props.payment && props.invoice?.order_type !== "Deposit" && (
                  <>
                    {/* Pay All Button */}
                    <Button
                      className="ml-2 bg-primary"
                      onClick={() => {
                        setAmount(Number(price ?? 0));
                        setWalletPaymentAmount(0);
                        setPaymentConfirmed(false);
                      }}
                    >
                      <i className="bx bx-male font-size-15 mr-2" />
                      {`↓ ${i18n.t("Pay all")} ↓`}
                    </Button>
                    <i className="font-size-15 mr-2" />
                    {`|`}
                    {/* Wallet Pay Remain Button */}
                    <Button
                      className="ml-2 bg-info"
                      onClick={() => {
                        const billingAmount = new Decimal(price ?? 0);
                        const payAmount = new Decimal(amount ?? 0);
                        const remainingAmount = billingAmount.minus(payAmount);
                        const walletAmount = new Decimal(wallet?.amount ?? 0);

                        let walletPayment = Decimal.min(
                          remainingAmount,
                          walletAmount
                        );

                        setWalletPaymentAmount(
                          walletPayment.greaterThanOrEqualTo(0)
                            ? walletPayment.toNumber()
                            : 0
                        );
                        setAmount(payAmount.toNumber());
                        setPaymentConfirmed(false);
                      }}
                    >
                      <i className="bx bx-male font-size-15" />
                      <i className="bx bx-plus font-size-15" />
                      <i className="bx bx-wallet font-size-15 mr-2" />
                      {i18n.t("Wallet Pay Remain")}
                    </Button>
                    {/* Wallet Pay All Button */}
                    <Button
                      className="ml-2 bg-info"
                      onClick={() => {
                        const billingAmount = new Decimal(price ?? 0);
                        const walletAmount = new Decimal(wallet?.amount || 0);
                        setPaymentConfirmed(false);
                        if (walletAmount.greaterThanOrEqualTo(billingAmount)) {
                          setWalletPaymentAmount(billingAmount.toNumber());
                          setAmount(0);
                        } else {
                          setWalletPaymentAmount(walletAmount.toNumber());
                          setAmount(
                            billingAmount.minus(walletAmount).toNumber()
                          );
                        }
                      }}
                    >
                      <i className="bx bx-wallet font-size-15 mr-2" />
                      {i18n.t("Wallet Pay All")}
                    </Button>

                    {/* Clean All Button */}
                    <Button
                      className="ml-2 bg-info"
                      onClick={() => {
                        setAmount(0);
                        setWalletPaymentAmount(0);
                        setPaymentConfirmed(false);
                      }}
                    >
                      <i className="bx bxs-eraser font-size-15 mr-2" />
                      {i18n.t("Clean All")}
                    </Button>
                  </>
                )}
              </Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <CurrencySymbol
                      currency={contextBranch?.currency ?? "HKD"}
                    />
                  </span>
                </div>
                <Input
                  className={
                    props.invoice?.order_type === "Deposit"
                      ? "bg-light text-gray bg-opacity-10"
                      : `${
                          props.mode === "edit" &&
                          "bg-light text-gray bg-opacity-10"
                        }`
                  }
                  disabled={
                    props.mode === "edit" ||
                    props.invoice?.order_type === "Deposit"
                  }
                  value={
                    props.invoice?.order_type === "Deposit"
                      ? Number(price ?? 0)
                      : Number(amount)
                  }
                  name="amount"
                  type="number"
                  onChange={(e: any) => {
                    handlePaymentAmountChange(Number(e.target.value));
                  }}
                />
              </div>
            </FormGroup>

            {props.mode === "create" &&
              props.invoice?.order_type === "Rent" &&
              props.invoice?.invoice_type === "Payment" && (
                <FormGroup className="mb-4">
                  <Label htmlFor="validationCustomName">
                    {i18n.t("Paid by Prepaid Amount")}
                  </Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <CurrencySymbol
                          currency={contextBranch?.currency ?? "HKD"}
                        />
                      </span>
                    </div>
                    <Input
                      className={
                        walletError ? "bg-danger text-gray bg-opacity-10" : ""
                      }
                      value={Number(walletPaymentAmount ?? 0)}
                      name="walletPaymentAmount"
                      type="number"
                      onChange={(e: any) =>
                        handleWalletPaymentAmountChange(Number(e.target.value))
                      }
                    />

                    <div>
                      <p className="text-muted mb-2">
                        <i className="bx bx-wallet font-size-15 mr-2" />
                        {`${i18n.t("Wallet amount")}:`}
                        <span className="ml-2 font-size-15 text-success">
                          <CurrencySymbol
                            currency={contextBranch?.currency ?? "HKD"}
                          />
                          {wallet?.amount}
                        </span>
                      </p>
                    </div>
                  </div>
                  {walletError && (
                    <div className="error-message text-danger">
                      {i18n.t("Your wallet balance is insufficient.")}
                    </div>
                  )}
                </FormGroup>
              )}
            {props.mode === "create" && (
              <FormGroup className="mb-4">
                <Label htmlFor="validationCustomName">
                  {i18n.t("Total Pay Amount")}
                </Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <CurrencySymbol
                        currency={contextBranch?.currency ?? "HKD"}
                      />
                    </span>
                  </div>
                  <Input
                    className={"bg-light text-gray bg-opacity-10"}
                    disabled={true}
                    value={
                      props.invoice?.order_type === "Deposit"
                        ? Number(price ?? 0)
                        : new Decimal(amount ?? 0)
                            .plus(walletPaymentAmount ?? 0)
                            .toNumber() ?? 0
                    }
                    name="amount"
                    type="number"
                  />
                </div>
              </FormGroup>
            )}
            <Row>
              <Col>
                {payment?.status !== "PAID" &&
                  payment?.status !== "VOID" &&
                  payment?.status !== "CANCELLED" && (
                    <Button
                      className="bg-success mb-4"
                      disabled={!!walletError}
                      onClick={() => {
                        if (props.invoice?.order_type === "Deposit") {
                          setAmount(Number(price ?? 0));
                          setWalletPaymentAmount(0);
                        }
                        setPaymentConfirmed(true);
                      }}
                    >
                      {i18n.t("Confirm Amount")}
                    </Button>
                  )}

                {payment?.status !== "PAID" &&
                  payment?.status !== "VOID" &&
                  payment?.status !== "CANCELLED" &&
                  paymentConfirmed &&
                  (() => {
                    const totalPayAmount = new Decimal(amount ?? 0).plus(
                      walletPaymentAmount ?? 0
                    );
                    const billingAmount = new Decimal(price ?? 0);
                    const difference = totalPayAmount.minus(billingAmount);

                    if (difference.equals(0)) {
                      return (
                        <Alert
                          color="success"
                          className="offcanvas alert-dismissible fade show mb-4 p-3 text-center font-size-13"
                          role="alert"
                        >
                          <p>
                            <b>
                              <span
                                className={`font-size-13 mt-2 mb-2 badge badge-pill badge-success`}
                              >
                                {i18n.t("COMPLETED")}
                              </span>
                              <p>{`${i18n.t(
                                "You have paid full amount of this payment."
                              )}`}</p>
                            </b>
                          </p>
                        </Alert>
                      );
                    } else if (difference.greaterThan(0)) {
                      return (
                        <>
                          <Alert
                            color="success"
                            className="offcanvas alert-dismissible fade show mb-4 p-3 text-center font-size-13"
                            role="alert"
                          >
                            <p>
                              <b>
                                <span
                                  className={`font-size-13 mt-2 mb-2 badge badge-pill badge-success`}
                                >
                                  {i18n.t("COMPLETED")}
                                </span>
                                <p>
                                  <CurrencySymbol
                                    currency={contextBranch?.currency ?? "HKD"}
                                  />
                                  {difference.toNumber()}
                                </p>
                                <p>{`${i18n.t(
                                  "The excess amount will be stored in the wallet."
                                )}`}</p>
                                <p>{`${i18n.t(
                                  "You can check the amount in the Order, or the transaction in Customer page."
                                )}`}</p>
                              </b>
                            </p>
                          </Alert>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Alert
                            color="danger"
                            className="offcanvas alert-dismissible fade show mb-4 p-3 text-center font-size-13"
                            role="alert"
                          >
                            <p>
                              <b>
                                <span
                                  className={`font-size-13  mt-2 mb-2 badge badge-pill badge-danger`}
                                >
                                  {i18n.t("PARTIAL COMPLETED")}
                                </span>
                                <p>
                                  -
                                  <CurrencySymbol
                                    currency={contextBranch?.currency ?? "HKD"}
                                  />
                                  {`${Math.abs(difference.toNumber())}`}
                                </p>
                                <p>{`${i18n.t(
                                  "The customer still needs to make a payment for this amount. Please allocate the payment accordingly."
                                )}`}</p>
                              </b>
                            </p>
                          </Alert>
                        </>
                      );
                    }
                  })()}
              </Col>
            </Row>
            <Row>
              <Col>
                {paymentMethodGroup?.value !== "CASH" && (
                  <FormGroup>
                    <Label>{i18n.t("Reference No")}:</Label>
                    <Input
                      disabled={props.payment?.status === "VOID"}
                      className={`${
                        props.payment?.status === "VOID"
                          ? "bg-light text-gray bg-opacity-10"
                          : ""
                      }`}
                      value={refNo}
                      name="refNo"
                      type="text"
                      onChange={(e) => {
                        setRefNo(e.target.value);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
            <FormGroup className="border-top border-5 pt-4 md-4">
              <Label>{`${i18n.t("Attachments")}`}</Label>
              <Row>
                <Col>
                  {uploadedDocs?.map((file, i) => {
                    return (
                      <div key={i}>
                        {
                          <img
                            alt={file.name}
                            className="attachmentIcon"
                            src={PNGcon}
                          />
                        }
                        {file?.name}
                      </div>
                    );
                  })}
                </Col>
                <Col xs="2">
                  <Button
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    <i className="mdi mdi-cloud-upload-outline mr-2" />
                    {i18n.t("Upload")}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="border-top border-5 pt-4 md-4">
              <Label>{`${i18n.t("Payment")} ${i18n.t("Remarks")}`}</Label>
              <Input
                type="textarea"
                maxLength={500}
                name="remarks"
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks ?? ""}
              />
            </FormGroup>
            <Row>
              <Col>
                <BxButton
                  loading={submitButtonBlock}
                  disabled={!paymentConfirmed || submitButtonBlock}
                  type="submit"
                  color={props.onClose ? "success" : "primary"}
                >
                  {i18n.t("Submit")}
                </BxButton>
                {props.onClose && (
                  <Button
                    type="button"
                    color="secondary"
                    onClick={props.onClose ? props.onClose : props.onClose}
                  >
                    {i18n.t("Close")}
                  </Button>
                )}
              </Col>
            </Row>
            <div className={"error mt-2"}>
              {paymentMethodGroupError ? paymentMethodGroupError : ""}
            </div>
          </CardBody>
        </Card>
      </AvForm>
    </div>
  );
};
export default PaymentInfo;
