import React, { useEffect, useMemo, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Card,
  Button,
  CardBody,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { BxButton } from "../../Button";
import { useTranslation } from "react-i18next";
import "./storageRent.scss";
import { useBranchContext } from "../../../context/BranchProviderContext";
import { Storage } from "src/models/storage";
import { ChargeType } from "src/models/chargeType";
import { MultiLangText } from "src/models/multiLangText";
import { PaidItem } from "src/models/paidItem";
import Decimal from "decimal.js";
import { Tr, Th, Td } from "react-super-responsive-table";
import ChargeTypePaidItem from "src/pages/Deposit/components/ChargeTypePaidItem";
import StoragePaidItem from "src/pages/Deposit/components/StoragePaidItem";
import { Branch } from "src/models/branch";
import { InvoiceType } from "src/models/invoice";
import { OrderType } from "src/util/orderType";
import { CreateDepositOrderRequest } from "src/helpers/boxapp_order_helper";
import { MiscellaneousPaidItem } from "src/pages/Deposit/components/MiscellaneousPaidItem";
import CurrencySymbol from "src/components/CurrencySymbol";

export interface DepositPanelProps {
  // PaidItems?: PaidItem[];
  branch?: Branch;
  step: number;
  onSubmit: (data: CreateDepositOrderRequest) => void;
  storage?: Storage;
  isFinished: boolean;
  onCancel: () => void;
  onDepositRequireChange?: (depositRequire: boolean) => void;
  open: boolean;
}

const DepositPanel = (props: DepositPanelProps) => {
  const { i18n } = useTranslation();
  const [invoiceRemarks, setInvoiceRemarks] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");

  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [itemDropDown, setItemDropDown] = useState<boolean>(false);
  const { contextBranch } = useBranchContext();
  const [selectedChargeTypePaidItem, setSelectedChargeTypePaidItem] =
    useState<PaidItem>();
  const [selectedStoragePaidItem, setSelectedStoragePaidItem] =
    useState<PaidItem>(); // [selectedmodalStoragePaidItem, setSelectedmodalStoragePaidItem
  const [selectedMiscellaneousPaidItem, setSelectedMiscellaneousPaidItem] =
    useState<PaidItem>();
  const [modalStoragePaidItem, setModalStoragePaidItem] =
    useState<boolean>(false);

  const [modalMiscellaneousPaidItem, setModalMiscellaneousPaidItem] =
    useState<boolean>(false);
  const [modalChargeTypePaidItem, setModalChargeTypePaidItem] =
    useState<boolean>(false);
  // no `async` keyword. and using callback inside the function body
  const closeModal = () => {
    setModalStoragePaidItem(false);
    setModalChargeTypePaidItem(false);
    setModalMiscellaneousPaidItem(false);
  };

  // useEffect(() => {
  //   if (props.PaidItems) {
  //     setPaidItems(
  //       props.PaidItems?.map((item) => {
  //         if (!item?.id) {
  //           item.id = uuid();
  //         }
  //         return item;
  //       })
  //     );
  //   }
  // }, [props.PaidItems]);
  const totalDepositAmount = useMemo(() => {
    return paidItems?.reduce(
      (total, item) => total.plus(item?.original_price ?? 0),
      new Decimal(0)
    );
  }, [paidItems]);
  return (
    <div>
      {props.branch && (
        <ChargeTypePaidItem
          // order={props.order}
          branch={props.branch}
          invoiceType={InvoiceType.Payment}
          orderType={OrderType?.Deposit}
          // storage={storage}
          // mode={mode}
          modal={modalChargeTypePaidItem}
          onSuccess={async (paidItem) => {
            if (paidItem?.id) {
              //   await updatePaidItem(paidItem);
              setPaidItems(
                paidItems?.map((item, i) =>
                  item.id === paidItem.id
                    ? {
                        ...paidItem,
                        sort: i,
                      }
                    : item
                )
              );
            } else {
              setPaidItems([
                ...(paidItems ?? []),
                {
                  ...paidItem,
                  sort: paidItems?.length,
                  id: crypto.randomUUID(),
                },
              ]);
            }
            closeModal();
            // props.onSuccess();
          }}
          onClose={closeModal}
          onFailed={() => {
            closeModal();
            // props.onSuccess();
          }}
        />
      )}
      {props.branch && props.storage && (
        <StoragePaidItem
          // order={props.order}
          branch={props.branch}
          invoiceType={InvoiceType.Payment}
          orderType={OrderType?.Deposit}
          storage={props.storage}
          modal={modalStoragePaidItem}
          onSuccess={async (paidItem) => {
            //add paid item api
            if (paidItem?.id) {
              //   await updatePaidItem(paidItem);
              setPaidItems(
                paidItems?.map((item, i) =>
                  item.id === paidItem.id
                    ? {
                        ...paidItem,
                        sort: i,
                      }
                    : item
                )
              );
            } else {
              setPaidItems([
                ...(paidItems ?? []),
                {
                  ...paidItem,
                  sort: paidItems?.length,
                  id: crypto.randomUUID(),
                },
              ]);
            }
            closeModal();
            // props.onSuccess();
          }}
          onClose={closeModal}
          onFailed={() => {
            closeModal();
            // props.onSuccess();
          }}
        />
      )}
      {props.branch && (
        <MiscellaneousPaidItem
          // order={props.order}
          branch={props.branch}
          invoiceType={InvoiceType.Payment}
          modal={modalMiscellaneousPaidItem}
          // item={selectedMiscellaneousPaidItem}
          onSuccess={async (paidItem: PaidItem) => {
            if (paidItem?.id) {
              setPaidItems(
                paidItems?.map((item, i) =>
                  item.id === paidItem.id
                    ? {
                        ...paidItem,
                        sort: i,
                      }
                    : item
                )
              );
            } else {
              setPaidItems([
                ...paidItems,
                {
                  ...paidItem,
                  sort: paidItems?.length,
                  id: crypto.randomUUID(),
                },
              ]);
            }
            // setSelectedMiscellaneousPaidItem(undefined);
            closeModal();
          }}
          onClose={closeModal}
          onFailed={() => {
            closeModal();
            // props.onSuccess();
          }}
        />
      )}
      <AvForm
        hidden={!props.open}
        action="#"
        id={"CollapseForm" + props.step}
        title={i18n.t("Deposit")}
        onSubmit={() => {
          if (
            !paidItems?.every(
              (item) =>
                new Decimal(item.original_price ?? 0).isPositive() &&
                item.item_type &&
                item.item_type?.length > 0
            )
          ) {
            return;
          }

          const items: PaidItem[] = paidItems?.map((item) => {
            const chargeType = contextBranch?.charge_types?.find(
              (chargeType) => chargeType.id === item?.item_id
            );

            // amounts += Number(item.original_price);
            item.final_price = Number(item.original_price);
            item.original_price = Number(item.original_price);
            item.discount_amount = 0;
            item.order_type = OrderType?.Deposit;
            if (item.item_type === "STORAGE") {
              item.item_id = props.storage?.id;
              item.item = {
                ...props.storage,
                branch: undefined,
                categories: undefined,
                charge_types: undefined,
                contract_id: undefined,
                contract: undefined,
                customer_id: undefined,
                features: undefined,
                order: undefined,
                order_id: undefined,
                rent_end_date: undefined,
                rent_records: undefined,
              } as Storage;
            } else if (item.item_type === "MISCELLANEOUS") {
              // item.item_id = props.storage?.id;
              item.item = {
                ...item,
              } as any;
            } else {
              item.item_name = chargeType?.charge_name;
              item.item = {
                ...chargeType,
                order_id: undefined,
                order_type: undefined,
                invoice_type: undefined,
                invoice_id: undefined,
                item: undefined,
                paid_amount: undefined,
                need_to_pay: undefined,
                rent_type: undefined,
                rent_period: undefined,
                roundUp: undefined,
                roundDown: undefined,
              } as ChargeType;
            }

            return item;
          });
          props.onSubmit({
            original_price: totalDepositAmount?.toNumber() ?? 0,
            discount_amount: 0,
            final_price: totalDepositAmount?.toNumber() ?? 0,
            remarks: remarks,
            paid_items: items,
            invoice_remarks: invoiceRemarks,
            customer_id: "",
          });
        }}
      >
        <Card outline color="light" className="border">
          <CardBody>
            {
              <Row className="mb-2">
                <Col xs="10">
                  <Label className="font-12" htmlFor="">
                    {i18n.t("Deposit Items")}
                  </Label>
                </Col>
              </Row>
            }
            {
              <Row className="mb-4">
                <Col xs={12}>
                  <table className="table table-striped ">
                    <thead>
                      <Tr>
                        <Th className="co-name text-center">
                          {i18n.t("Paid Items")}
                        </Th>
                        <Th className="co-name">{i18n.t("Deposit")}</Th>
                      </Tr>
                    </thead>
                    <tbody>
                      {paidItems?.map((item, i) => {
                        return (
                          <Tr
                            key={i}
                            xl="2"
                            lg="2"
                            sm="4"
                            xs="6"
                            className={`col-sm-1 col-md-1 mt-1 mr-1`}
                          >
                            <Td>
                              <i
                                className="btn text-primary mdi mdi-file-document-edit-outline font-size-18 "
                                onClick={() => {
                                  switch (item?.item_type) {
                                    case "STORAGE":
                                      setSelectedStoragePaidItem(item);
                                      setModalStoragePaidItem(true);

                                      break;
                                    case "MISCELLANEOUS":
                                      setSelectedMiscellaneousPaidItem(item);
                                      setModalMiscellaneousPaidItem(true);
                                      break;
                                    default:
                                      setSelectedChargeTypePaidItem(item);
                                      setModalChargeTypePaidItem(true);
                                      break;
                                  }
                                }}
                              ></i>
                              <i
                                className="btn text-primary mdi mdi-trash-can-outline font-size-18  "
                                onClick={async () => {
                                  setPaidItems(
                                    paidItems?.filter(
                                      (paidItem) => paidItem?.id !== item?.id
                                    )
                                  );
                                }}
                              ></i>
                              {item?.item_type === "STORAGE"
                                ? `${i18n.t(item?.item_type)} - ${
                                    (item?.item as Storage)?.code
                                  }`
                                : item?.item_type === "WALLET"
                                ? `${i18n.t("Wallet Paid")}`
                                : item?.item_type === "PREPAID"
                                ? `${i18n.t("Extra Amount")}`
                                : item?.item_type === "REPAY"
                                ? `${i18n.t("Repay")}`
                                : item?.item_name?.[
                                    i18n.language as keyof MultiLangText
                                  ] ?? ""}
                            </Td>
                            <Td>{item.final_price}</Td>
                          </Tr>
                        );
                      })}
                      <Tr>
                        <Td>
                          <ButtonDropdown
                            className="text-primary"
                            isOpen={itemDropDown}
                            toggle={() => setItemDropDown(!itemDropDown)}
                          >
                            <DropdownToggle caret color="link">
                              <i className="mdi mdi-plus font-size-18  "></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={async () => {
                                  setSelectedStoragePaidItem(undefined);
                                  setModalStoragePaidItem(true);
                                }}
                              >
                                {i18n.t("Storage Supplement Item")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setSelectedChargeTypePaidItem(undefined);
                                  setModalChargeTypePaidItem(true);
                                }}
                              >
                                {i18n.t("Other Charge Items")}
                              </DropdownItem>

                              <DropdownItem
                                onClick={async () => {
                                  setSelectedMiscellaneousPaidItem(undefined);
                                  setModalMiscellaneousPaidItem(true);
                                }}
                              >
                                {i18n.t("Miscellaneous Item")}
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Td>

                        <Td></Td>
                      </Tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            }
            {
              <Row>
                <Col xs="12">
                  <FormGroup className="mb-4">
                    <Label className="font-size-13 font-weight-bold">{`${i18n.t(
                      "Deposit"
                    )}${i18n.language === "en" ? " " : ""}${i18n.t(
                      "Final Price"
                    )}`}</Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text font-size-13 font-weight-bold">
                          <CurrencySymbol
                            currency={props?.branch?.currency ?? "HKD"}
                          />
                        </span>
                      </div>
                      <Input
                        disabled
                        className="bg-light text-gray bg-opacity-10 font-size-15 font-weight-bold"
                        type="number"
                        maxLength={8}
                        name="totalPrice"
                        value={totalDepositAmount?.toNumber()}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            }
            <FormGroup className="mb-4">
              <Label>{`${i18n.t("Order")} & ${i18n.t("Invoice")}${
                i18n.language === "en" ? " " : ""
              }${i18n.t("Remarks")}`}</Label>
              <Input
                type="textarea"
                maxLength={500}
                name="remarks"
                onChange={(e) => {
                  setRemarks(e.target.value ?? "");
                  setInvoiceRemarks(e.target.value ?? "");
                }}
                value={remarks}
              />
            </FormGroup>

            {!props.isFinished && (
              <BxButton
                type="submit"
                color="success"
                form={"CollapseForm" + props.step}
              >
                {i18n.t("Next Step")}
              </BxButton>
            )}
            {props.step === 0 && props.isFinished && (
              <BxButton
                type="submit"
                color="success"
                form={"CollapseForm" + props.step}
              >
                {i18n.t("Submit")}
              </BxButton>
            )}
          </CardBody>
        </Card>
      </AvForm>
    </div>
  );
};

export default DepositPanel;
