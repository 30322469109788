import React, { useState, useEffect } from "react";

import { withTranslation, useTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { BxButton } from "../../Button";

import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "./storage.scss";
// Redux

import { useBranchContext } from "../../../context/BranchProviderContext";
import { useStorageContext } from "../../../context/StorageProviderContext";
import { getBoxappStorageBackend } from "../../../helpers/boxapp_storage_helper";
import { MODAL_TYPES } from "../ModalProps";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { Customer } from "src/models/customer";
import { DepositOrder } from "src/models/depositOrder";
import { Order } from "src/models/order";

interface StorageMoveOutProps {
  modal: boolean;
  mode: string;
  order: Order;
  customer: Customer;
  depositOrder: DepositOrder;
  onClose: () => void;
  onSuccess: (mode: string, storage: any) => void;
}
const StorageMoveOut = (props: StorageMoveOutProps) => {
  const { contextStorage } = useStorageContext();
  const { i18n } = useTranslation();
  const [moveOutDate, setMoveOutDate] = useState(new Date());

  const { showModal } = useGlobalModalContext();
  const { contextBranch } = useBranchContext();

  const togglemodal = () => {
    setMoveOutDate(new Date());
    if (props.onClose) props.onClose();
  };

  const submit = async () => {
    if (!moveOutDate) {
      return;
    }
    try {
      const res = await getBoxappStorageBackend().moveOut(
        contextBranch?.id as string,
        contextStorage?.id as string,
        {
          order_id: props.order.id,
          storage_id: contextStorage?.id,
          customer_id: props.customer.id,
          move_out_date: moment(moveOutDate).startOf("day").unix(),
        }
      );

      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("Property Move Out Success"),
        messageType: "success",
        message: i18n.t("Update Property Success"),
      });
      if (res.storage && props.onSuccess) {
        props.onSuccess(props.mode, res.storage);
      }
    } catch (e) {
      const errorMsg =
        (e as any)?.data?.detail ??
        "" + (e as any)?.data?.message ??
        "" + e?.toString();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: i18n.t("Storage Move Out failed"),
        messageType: "error",
        message: errorMsg,
      });
      props.onClose();
    }
  };

  return !props.order ? null : (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className=""
        tabIndex={-1}
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            {i18n.t("Contract Terminate")}
          </ModalHeader>
          <ModalBody>
            <AvForm action="#" onSubmit={submit}>
              <Card outline color="dark" className="border">
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{i18n.t("Storage")} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{contextStorage?.code}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{i18n.t("Order No")} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{props.order.order_no}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{i18n.t("Customer")} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            {props.customer?.first_name +
                              props.customer?.last_name}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col>
                          <Label>{i18n.t("Contract No")} </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{props.order.contract_id}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {props.depositOrder && (
                    <Alert
                      color="danger"
                      className="alert-dismissible fade show  mb-4 pt- font-size-13"
                      role="alert"
                    >
                      <i className="mdi mdi-alert">
                        <Label>
                          <a data-tip data-for="tips1">
                            {i18n.t("Tipsⓘ")}
                          </a>
                        </Label>
                        <ReactTooltip id="tips1" effect="solid">
                          <span>
                            <p>
                              {i18n.t(
                                "The date of move out after termainated."
                              )}
                            </p>
                          </span>
                        </ReactTooltip>
                      </i>
                    </Alert>
                  )}

                  <Row>
                    <Col xs="12" className="mt-3">
                      <Label>{i18n.t("Move Out At")}</Label>
                      <InputGroup>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          name="moveOutDate"
                          selected={moveOutDate}
                          className="form-control"
                          onChange={(date: Date) => setMoveOutDate(date)}
                          showYearDropdown
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <BxButton type="submit" color="success">
                    {i18n.t("Submit")}
                  </BxButton>
                  <Button type="button" color="secondary" onClick={togglemodal}>
                    {i18n.t("Close")}
                  </Button>
                </CardBody>
              </Card>
            </AvForm>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(StorageMoveOut);
