import { Col, Row, Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import DataTable from "../../../components/DataTable";
import "../../../assets/css/datatables.scss";
import { useGlobalModalContext } from "../../../context/GlobalModalContext";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { useAuthContext } from "../../../context/AuthProviderContext";
import { getBoxappStorageBackend } from "src/helpers/boxapp_storage_helper";
import {
  RentRecord,
  orderStatusBadge,
  rentalStatusBadge,
} from "src/models/rentRecord";
import { Column } from "src/components/DataTable/ColumnFilter";
import { MultiLangText } from "src/models/multiLangText";
import { MoveOutDateUpdate } from "./moveOutDateUpdate";
import { getBoxappRentRecordBackend } from "src/helpers/boxapp_rent_record_helper";
import { Storage } from "src/models/storage";
import { MODAL_TYPES } from "src/components/Modal/ModalProps";

import { generateDownloadableHTML } from "src/models/downloadTableStylesAndHTML";
export interface StorageRentRecordListProps {
  columnMode?: string;
  branchID: string;
  storageID: string;
  storage?: Storage;
  onSelect?: (rentRecord: RentRecord) => void;
}

const StorageRentRecordList = (props: StorageRentRecordListProps) => {
  const { i18n } = useTranslation();

  const { showModal, loading, setLoading } = useGlobalModalContext();
  const { isMobile } = useGlobalModalContext();
  const [modal, setModal] = useState(false);
  const [selectedRentRecord, setSelectedRentRecord] = useState<RentRecord>();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rentRecords, setRentRecords] = useState<RentRecord[]>([]);

  const [data, setData] = useState<{ columns: Column[]; rows: any[] }>({
    columns: [],
    rows: [],
  });
  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const res = await getBoxappRentRecordBackend().getRentRecords({
        branch_id: props.branchID,
        storage_id: props.storageID,
        page: page,
        limit: limit,
        sort: "rental_orders.end_date:desc",
      });
      if (res.rent_records) {
        setRentRecords(res.rent_records.data);
        setTotalPage(res.rent_records.pageCount);
        setPage(res.rent_records.page);
        setTotalData(res.rent_records.total);
        setLoading(false);
      }
    } catch (e) {
      setRentRecords([]);
      setTotalPage(0);
      setPage(0);
      setTotalData(0);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(page, limit);
  }, [page, limit]);

  useEffect(() => {
    if (rentRecords) {
      setData({
        columns: props.columnMode === "LIGHT" ? popUpColumn : desktopColumn,
        rows: getRows(),
      });
    }
  }, [rentRecords]);
  const { checkPermission } = useAuthContext();

  function downloadTableAsHTML() {
    const currentDate = new Date().toISOString().slice(0, 10);
    const titleText = `${props?.storage?.code} - ${
      props.storage?.address?.[i18n.language as keyof MultiLangText] ?? ""
    } - ${i18n.t("Rent Records")}`;
    const printFileNames = [
      `${props?.storage?.code} - Rent Records - ${currentDate}`,
    ];
    const downloadDateLabel = i18n.t("Download Date");
    generateDownloadableHTML(titleText, printFileNames, downloadDateLabel);
  }

  const getRows = () => {
    return rentRecords?.map((rentRecord) => {
      let row: any = Object.assign({}, rentRecord);
      const today = moment().format("YYYY-MM-DD");
      const startDiff = moment
        .unix(rentRecord?.rent_start_date)
        .diff(moment(today), "days");
      const endDiff = moment
        .unix(rentRecord?.rent_end_date)
        .diff(moment(today), "days");

      row["customer"] = (
        <Link
          to={
            checkPermission("PAGE:CUSTOMER")
              ? `/customers/${rentRecord?.customer_id}`
              : "/error-403"
          }
          className="card-link"
        >
          {rentRecord?.customer?.other_name}
        </Link>
      );
      row["contractNo"] = (
        <Link
          to={
            checkPermission("PAGE:CONTRACT")
              ? `/contracts/${rentRecord?.contract_id}`
              : "/error-403"
          }
          className="card-link"
        >
          {rentRecord?.contract_id}
        </Link>
      );

      row["orderNo"] = (
        <Link
          to={
            checkPermission("PAGE:STORAGE:RENTRECORD")
              ? `/order/${rentRecord?.order_id}`
              : "/error-403"
          }
          className="card-link"
        >
          {rentRecord?.order_no}
        </Link>
      );

      row["price"] = rentRecord?.price;
      row["contractStartDate"] = moment
        .unix(rentRecord?.contract?.start_date)
        .format("YYYY-MM-DD");
      row["contractEndDate"] = moment
        .unix(rentRecord?.contract?.end_date)
        .format("YYYY-MM-DD");

      row["orderDate"] = moment
        .unix(rentRecord?.order_date)
        .format("YYYY-MM-DD");
      row["startDate"] = moment
        .unix(rentRecord?.rent_start_date)
        .format("YYYY-MM-DD");
      row["endDate"] = moment
        .unix(rentRecord.rent_end_date)
        .format("YYYY-MM-DD");
      if (rentRecord?.status === "TERMINATED") {
        row["isTodayWithinRentPeriod"] = (
          <span className="badge badge-pill badge-secondary">{`${i18n.t(
            "Contract End"
          )}`}</span>
        );
      } else if (rentRecord?.status === "CANCELLED") {
        row["isTodayWithinRentPeriod"] = (
          <span className="badge badge-pill badge-secondary">{`${i18n.t(
            "Cancelled"
          )}`}</span>
        );
      } else if (
        moment(today).isBetween(
          moment.unix(rentRecord?.rent_start_date).format("YYYY-MM-DD"),
          moment.unix(rentRecord.rent_end_date).format("YYYY-MM-DD"),
          "day",
          "[]"
        )
      ) {
        row["isTodayWithinRentPeriod"] = (
          <i className="fas fa-check-circle font-size-13 ml-2 badge badge-pill badge-success">{` ${i18n.t(
            "Yes"
          )}`}</i>
        );
      } else if (startDiff > 0) {
        row["isTodayWithinRentPeriod"] = (
          <span className="badge badge-pill badge-primary">{`${i18n.t(
            "Start after"
          )} ${startDiff} ${i18n.t("Days")}`}</span>
        );
      } else if (endDiff < 0) {
        row["isTodayWithinRentPeriod"] = (
          <span className="badge badge-pill badge-secondary">{`${i18n.t(
            "End already"
          )} ${-endDiff} ${i18n.t("Days")}`}</span>
        );
      } else {
        row["isTodayWithinRentPeriod"] = "-";
      }

      row["moveOutDate"] = (
        <div>
          {rentRecord?.move_out_date > 0
            ? moment.unix(rentRecord?.move_out_date).format("YYYY-MM-DD")
            : "-"}
          {rentRecord?.move_out_date > 0 && (
            <i
              className="btn text-primary mdi mdi-file-document-edit-outline font-size-18 "
              onClick={() => {
                setSelectedRentRecord(rentRecord);
                setModal(true);
              }}
            ></i>
          )}
        </div>
      );
      row["rentPeriod"] =
        rentRecord?.rent_period + " " + i18n.t(rentRecord?.rent_type);
      row["orderStatus"] = (
        <span
          className={`font-size-13 ml-2 badge badge-pill ${
            orderStatusBadge[rentRecord?.order_status] || "badge-danger"
          }`}
        >
          {i18n.t(rentRecord?.order_status)}
        </span>
      );
      row["rentalStatus"] = (
        <span
          className={`font-size-13 ml-2 badge badge-pill  ${
            rentalStatusBadge[rentRecord?.status] || "badge-danger"
          }`}
        >
          {i18n.t(rentRecord?.status)}
        </span>
      );
      row["currentView"] = (
        <Link
          to={"#"}
          onClick={async () => {
            try {
              const res = await getBoxappRentRecordBackend().setCurrentView({
                order_id: rentRecord?.order_id,
                storage_id: props.storageID,
              });

              if (props.onSelect && res?.rent_record)
                props.onSelect(res?.rent_record);
            } catch (e) {
              console.log(e);
            } finally {
              await fetchData(page, limit);
            }
          }}
        >
          {rentRecord?.current_view ? (
            <i className="bx bx bx-check-square font-size-18 mr-2"></i>
          ) : (
            <i className="bx bx-square font-size-18 mr-2"></i>
          )}
        </Link>
      );
      return row;
    });
  };
  const desktopColumn: Column[] = [
    {
      label: i18n.t("Customer"),
      field: "customer",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Contract Number"),
      field: "contractNo",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Contract Start Date"),
      field: "contractStartDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Contract End Date"),
      field: "contractEndDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Order no"),
      field: "orderNo",
      sortable: false,
      searchable: false,
    },

    {
      label: i18n.t("Order Created Date"),
      field: "orderDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Order Start Date"),
      field: "startDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Order End Date"),
      field: "endDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Rent Period"),
      field: "rentPeriod",
      sortable: false,
      searchable: false,
    },

    {
      label: i18n.t("Today Within Period"),
      field: "isTodayWithinRentPeriod",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Move Out Date"),
      field: "moveOutDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Price"),
      field: "price",
      sortable: false,
      searchable: false,
    },
    {
      label: `${i18n.t("Order")}${i18n.language === "en" ? " " : ""}${i18n.t(
        "Status"
      )}`,
      field: "orderStatus",
      sortable: false,
      searchable: false,
    },
    {
      label: `${i18n.t("Rental")}${i18n.language === "en" ? " " : ""}${i18n.t(
        "Status"
      )}`,
      field: "rentalStatus",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Current View"),
      field: "currentView",
      sortable: false,
      searchable: false,
    },
  ];

  const popUpColumn: Column[] = [
    {
      label: i18n.t("Customer"),
      field: "customer",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Contract Start Date"),
      field: "contractStartDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Contract End Date"),
      field: "contractEndDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Order Start Date"),
      field: "startDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Order End Date"),
      field: "endDate",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Today Within Period"),
      field: "isTodayWithinRentPeriod",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Move Out Date"),
      field: "moveOutDate",
      sortable: false,
      searchable: false,
    },
    {
      label: `${i18n.t("Order")}${i18n.language === "en" ? " " : ""}${i18n.t(
        "Status"
      )}`,
      field: "orderStatus",
      sortable: false,
      searchable: false,
    },
    {
      label: `${i18n.t("Rental")}${i18n.language === "en" ? " " : ""}${i18n.t(
        "Status"
      )}`,
      field: "rentalStatus",
      sortable: false,
      searchable: false,
    },
    {
      label: i18n.t("Current View"),
      field: "currentView",
      sortable: false,
      searchable: false,
    },
  ];
  const closeModal = () => {
    fetchData(page, limit);
    setModal(false);
  };
  return (
    <React.Fragment>
      <Row>
        <Col className="mt-2 mb-2">
          <div className="text-sm-right">
            <Button
              color="success"
              className="waves-effect waves-light "
              onClick={downloadTableAsHTML}
            >
              <i className="bx bx-download font-size-16 align-middle mr-2"></i>
              {i18n.t("Simple Data Download")}
            </Button>
          </div>
        </Col>
      </Row>
      <MoveOutDateUpdate
        modal={modal}
        storage={props.storage}
        rentRecord={selectedRentRecord}
        onSuccess={async (payload) => {
          try {
            await getBoxappRentRecordBackend().update({
              storage_id: props.storageID,
              ...payload,
            });
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: i18n.t("Update Rent Record Success"),
              messageType: "success",
              message: i18n.t("Update Rent Record Success"),
            });
            closeModal();
          } catch (e) {
            const errorMsg =
              (e as any)?.data?.detail ??
              "" + (e as any)?.data?.message ??
              "" + e?.toString();
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: i18n.t("Update Rent Record failed"),
              messageType: "error",
              message: errorMsg,
            });
          }
        }}
        onClose={() => {
          setModal(false);
        }}
        onFailed={() => {
          closeModal();
        }}
      />

      <DataTable
        noRecordsFoundLabel={i18n.t("No Records")}
        responsive
        striped
        displayEntries={!isMobile()}
        exportToCSV
        entriesOptions={[5, 10, 20, 30]}
        data={data}
        loading={loading}
        activePage={Number(page)}
        totalPage={Number(totalPage)}
        totalData={Number(totalData)}
        entitiesCount={Number(limit)}
        onSort={(field, order) => {}}
        onEntitiesCountChange={async (limit) => {
          setLimit(limit);
          setData({
            columns: data.columns,
            rows: [],
          });
          await fetchData(0, limit);
        }}
        onPage={async (page) => {
          setData({
            columns: data.columns,
            rows: [],
          });
          await fetchData(page, limit);
        }}
        onSearch={async (value) => {
          // if (value) {
          //   const rs = getRows();
          //   setData({
          //     columns: data.columns,
          //     rows:
          //       rs.filter((r) => r.expend_no.includes(value)) ?? [],
          //   });
          //   setPage(0);
          //   await fetchData(0, limit);
          // }
        }}
        onClear={() =>
          setData({
            columns: data.columns,
            rows: getRows(),
          })
        }
      />
    </React.Fragment>
  );
};

export default withTranslation()(StorageRentRecordList);
